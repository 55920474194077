import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, brandColours } from '../styles';
import { Button } from './ui';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';
import ProductOptions from './ProductOptions';
import { useVariantPrice, formatPrice, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';
import { ProductContext } from './Product';

const StyledPaperSamples = styled(PopOutSidebar)`
  right: 0;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  color: ${brandColours.tertiary};
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const PaperSamples = ({
  options,
  handleChange,
  variants,
  getVariant,
  selectedVariant,
  paperTypes,
  locale,
}) => {
  const { isOverlayActive, setOverlayActive, addProductsToCheckout, checkout } =
    useContext(StoreContext);

  const { selectedSampleVariant, isPaperSamplesOpen, togglePaperSamplesOpen } =
    useContext(ProductContext);

  const { lineItems: currentLineItems } = checkout;

  const sampleVariantPrice = useVariantPrice(selectedSampleVariant);

  useEffect(() => {
    if (!isOverlayActive && isPaperSamplesOpen) {
      setOverlayActive(isPaperSamplesOpen);
    }
  }, [isOverlayActive, isPaperSamplesOpen, setOverlayActive]);

  const addSampleToCart = async () => {
    const alreadyInCart = currentLineItems.find(
      ({ variant }) => variant.id === selectedSampleVariant.storefrontId
    );

    const samplesInCart = currentLineItems.filter(
      ({ variant: { selectedOptions } }) =>
        selectedOptions.find(
          ({ name, value }) => name === 'Product Type' && value === 'Sample'
        )
    );

    if (alreadyInCart) {
      alert('You already have this sample in your cart.');
    } else if (samplesInCart.length >= 10) {
      alert('You already have 10 samples in your cart.');
    } else {
      const lineItems = [
        {
          variantId: selectedSampleVariant.storefrontId,
          quantity: 1,
        },
      ];

      await addProductsToCheckout(lineItems);
    }
  };

  return (
    <StyledPaperSamples
      heading={translateString('samples.header', locale)}
      isOpen={isPaperSamplesOpen}
      toggleOpen={togglePaperSamplesOpen}
    >
      <StyledContent>
        <ProductOptions
          options={options}
          handleChange={handleChange}
          variants={variants}
          getVariant={getVariant}
          selectedVariant={selectedVariant}
          paperTypes={paperTypes}
          locale={locale}
          isPaperSamples={true}
        />
        <StyledButton onClick={addSampleToCart}>
          {translateString('samples.addToCart', locale)} -{' '}
          {sampleVariantPrice && (
            <span>
              {formatPrice({
                amount: sampleVariantPrice.amount,
                currencyCode: sampleVariantPrice.currencyCode,
                locale: locale,
              })}
            </span>
          )}
        </StyledButton>
      </StyledContent>
    </StyledPaperSamples>
  );
};

export default PaperSamples;
