import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Heading } from './ui';
import CreateTradeAccountForm from './CreateTradeAccountForm';

const StyledTradeSignUp = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledStepsWrapper = styled.div`
  padding: 20px;
  background-color: ${brandColours.senary};

  ${minBreakpointQuery.small`
    padding: 30px;
  `}

  ${minBreakpointQuery.smedium`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.small`
    margin-bottom: 26px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 32px;
  `}
`;

const StyledSteps = styled.ol`
  display: grid;
  gap: 20px;
  counter-reset: number-counter;

  ${minBreakpointQuery.small`
    gap: 26px;
  `}

  ${minBreakpointQuery.large`
    gap: 32px;
  `}
`;

const StyledStep = styled.li`
  position: relative;
  padding-left: 50px;
  counter-increment: number-counter;

  ${minBreakpointQuery.small`
    padding-left: 60px;
  `}

  &:before {
    content: counter(number-counter);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.bold};
    ${fontSize(16)};
    line-height: 1;
    background-color: ${standardColours.white};

    ${minBreakpointQuery.small`
      height: 44px;
      width: 44px;
    `}
  }
`;

const StyledStepHeading = styled.h3`
  color: ${standardColours.lightBlack};
  font-weight: ${fontWeights.semibold};
  ${fontSize(13)};
  line-height: 1.4;

  ${minBreakpointQuery.small`
    ${fontSize(14)};
  `}
`;

const StyledStepText = styled.p`
  margin-top: 6px;
  color: ${standardColours.darkestGrey};
  ${fontSize(12)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(13)};
  `}
`;

const TradeSignUp = ({ stepsHeading, steps, formHeading, formText }) => (
  <StyledTradeSignUp>
    <Container>
      <StyledInner>
        <StyledStepsWrapper>
          <StyledHeading>{stepsHeading}</StyledHeading>
          <StyledSteps>
            {steps.map(({ heading, text }, id) => (
              <StyledStep key={id}>
                <StyledStepHeading>{heading}</StyledStepHeading>
                <StyledStepText>{text}</StyledStepText>
              </StyledStep>
            ))}
          </StyledSteps>
        </StyledStepsWrapper>
        <CreateTradeAccountForm
          heading={formHeading}
          text={formText}
          isTradeSignUp={true}
        />
      </StyledInner>
    </Container>
  </StyledTradeSignUp>
);

export default TradeSignUp;
