import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { fontWeights, fontSize } from '../styles';
import { Link } from './ui';

const StyledCustomerPhotoCard = styled.article``;

const StyledHeading = styled.h3`
  margin-top: 12px;
  ${fontSize(16)};
  font-weight: ${fontWeights.semibold};
`;

const StyledText = styled.p`
  margin-top: 6px;
  ${fontSize(12)};
`;

const CustomerPhotoCard = ({ image, heading, text, product, ...props }) => (
  <StyledCustomerPhotoCard {...props}>
    <Link to={product}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
    </Link>
    <StyledHeading>
      <Link to={product}>{heading}</Link>
    </StyledHeading>
    {text && <StyledText>{text}</StyledText>}
  </StyledCustomerPhotoCard>
);

export default CustomerPhotoCard;
