import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { Container } from './ui';
import Breadcrumbs from './Breadcrumbs';
import ProductOtherCategories from './ProductOtherCategories';
import ProductGallery from './ProductGallery';
import ProductSummary from './ProductSummary';
import ProductSummaryV2 from './ProductSummaryV2';
import ProductInfo from './ProductInfo';
import { ProductContext } from './Product';
import { buildUrl } from '../utils';

const StyledProductDetail = styled.div`
  background-color: ${standardColours.lightestGrey};
`;

const StyledInner = styled.div``;

const StyledBreadcrumbsCategories = styled.div`
  padding: 20px 0;
`;

const StyledBreadcrumbsCategoriesInner = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledOtherCategories = styled(ProductOtherCategories)`
  ${maxBreakpointQuery.mlarge`
    margin-top: 20px;
  `}
`;

const StyledGallery = styled(ProductGallery)``;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  ${fontSize(14)}
`;

const StyledSummary = styled(ProductSummary)``;

const ProductDetail = ({
  locationData,
  mainCategory,
  otherCategories,
  extraImages,
  title,
  descriptiveName,
  reviews,
  options,
  variants,
  sampleVariants,
  paperTypes,
  iconPoints,
  excessMessaging,
  descriptionHtml,
  rollWidth,
  rollHeight,
  patternMatch,
  verticalPatternRepeat,
  fireRatings,
  installMethod,
  paperType,
  eco,
  accordionItems,
  zoomTooltipMessaging,
  datoCmsCroppingTool,
  maxCropImages,
  enableWideCrop,
  locale,
  version,
}) => {
  const { selectedVariant, setSelectedVariant, setSelectedSampleVariant } =
    useContext(ProductContext);

  const [selectedVariantImages, setSelectedVariantImages] = useState();

  const getVariant = (items, selectedOptions) =>
    items.find(
      item =>
        JSON.stringify({ ...item.selectedOptions }) ===
        JSON.stringify(selectedOptions)
    );

  const getParamVariant = (variants, variantParam) => {
    const colourVariant = variants.filter(({ selectedOptions }) =>
      selectedOptions.find(
        ({ name, value }) =>
          name === 'Color' &&
          value.toLowerCase() === (variantParam && variantParam.toLowerCase())
      )
    );

    return colourVariant.find(({ selectedOptions }) =>
      selectedOptions.find(
        ({ name, value }) =>
          name === 'Paper Type' && value.toLowerCase() === 'premium'
      )
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(locationData.search);
    const variantParam = urlParams.get('variant');

    const selectedParamVariant = getParamVariant(variants, variantParam);

    selectedParamVariant && setSelectedVariant(selectedParamVariant);

    const selectedParamSampleVariant = getParamVariant(
      sampleVariants,
      variantParam
    );
    selectedParamSampleVariant &&
      setSelectedSampleVariant(selectedParamSampleVariant);
  }, [
    locationData.search,
    variants,
    setSelectedVariant,
    sampleVariants,
    setSelectedSampleVariant,
  ]);

  useEffect(() => {
    const images = {
      mainImages: [selectedVariant.mainImage],
      thumbnailImages: [selectedVariant.thumbnailImage],
    };

    if (extraImages && selectedVariant === variants[0]) {
      images.mainImages.push(...extraImages.mainImages);
      images.thumbnailImages.push(...extraImages.thumbnailImages);
    }

    setSelectedVariantImages(images);
  }, [selectedVariant, variants, extraImages]);

  return (
    <StyledProductDetail>
      <StyledInner>
        <StyledBreadcrumbsCategories>
          <Container>
            <StyledBreadcrumbsCategoriesInner>
              <StyledBreadcrumbs
                currentPageTitle={title}
                items={
                  mainCategory
                    ? [
                        {
                          text: mainCategory.title,
                          urlPath: buildUrl(mainCategory.slug, mainCategory),
                        },
                      ]
                    : []
                }
                alt={true}
              />
              <StyledOtherCategories items={otherCategories} locale={locale} />
            </StyledBreadcrumbsCategoriesInner>
          </Container>
        </StyledBreadcrumbsCategories>
        <StyledGallery
          title={title}
          extraImages={extraImages}
          variants={variants}
          sampleVariants={sampleVariants}
          getVariant={getVariant}
          zoomTooltipMessaging={zoomTooltipMessaging}
          locale={locale}
        />
        {version !== 2 ? (
          <StyledSummary
            title={title}
            descriptiveName={descriptiveName}
            options={options}
            variants={variants}
            sampleVariants={sampleVariants}
            selectedVariantImages={selectedVariantImages}
            getVariant={getVariant}
            paperTypes={paperTypes}
            rollWidth={rollWidth}
            rollHeight={rollHeight}
            verticalPatternRepeat={verticalPatternRepeat}
            excessMessaging={excessMessaging}
            iconPoints={iconPoints}
            locale={locale}
          />
        ) : (
          <ProductSummaryV2
            title={title}
            descriptiveName={descriptiveName}
            descriptionHtml={descriptionHtml}
            reviews={reviews}
            options={options}
            variants={variants}
            sampleVariants={sampleVariants}
            selectedVariantImages={selectedVariantImages}
            getVariant={getVariant}
            paperTypes={paperTypes}
            rollWidth={rollWidth}
            rollHeight={rollHeight}
            patternMatch={patternMatch}
            verticalPatternRepeat={verticalPatternRepeat}
            installMethod={installMethod}
            excessMessaging={excessMessaging}
            iconPoints={iconPoints}
            datoCmsCroppingTool={datoCmsCroppingTool}
            maxCropImages={maxCropImages}
            enableWideCrop={enableWideCrop}
            locale={locale}
          />
        )}
      </StyledInner>
      {version !== 2 && (
        <ProductInfo
          descriptionHtml={descriptionHtml}
          sku={selectedVariant.sku}
          rollWidth={rollWidth}
          rollHeight={rollHeight}
          patternMatch={patternMatch}
          verticalPatternRepeat={verticalPatternRepeat}
          fireRatings={fireRatings}
          installMethod={installMethod}
          paperType={paperType}
          eco={eco}
          accordionItems={accordionItems}
          locale={locale}
        />
      )}
    </StyledProductDetail>
  );
};

export default ProductDetail;
