import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
} from '../styles';
import { Container, Heading, HtmlContent, Button } from './ui';
import { ProductContext } from './Product';
import { translateString } from '../utils';

const StyledImageContent = styled.section`
  ${({ version }) => {
    if (version === 1) {
      return css`
        ${sectionMargins()};
      `;
    } else {
      return css`
        color: ${brandColours.primary};
        background-color: ${brandColours.tertiary};
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  ${({ version }) => {
    if (version === 2) {
      return css`
        padding-right: 0;
        padding-left: 0;
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: grid;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  `}

  ${({ version }) => {
    if (version === 1) {
      return css`
        gap: 30px;

        ${minBreakpointQuery.smedium`
          gap: 40px;
        `}

        ${minBreakpointQuery.medium`
          gap: 60px;
        `}

        ${minBreakpointQuery.large`
          gap: 80px;
        `}

        ${minBreakpointQuery.xxlarge`
          gap: 100px;
        `}
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    aspect-ratio: 2/1;
    @supports not (aspect-ratio: 2/1) {
      max-height: 420px;
    }
  `}

  ${maxBreakpointQuery.small`
    aspect-ratio: 3/2;
  `}

  ${maxBreakpointQuery.tsmall`
    aspect-ratio: 4/3;
  `}

  ${minBreakpointQuery.smedium`
    height: 100%;
  `}

  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-row: 1;
          grid-column: 2;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  ${({ version }) => {
    if (version === 1) {
      return css`
        ${minBreakpointQuery.smedium`
          margin-top: 30px;
          margin-bottom: 30px;
        `}
      `;
    } else {
      return css`
        padding: 40px 20px;

        ${minBreakpointQuery.small`
          padding-top: 50px;
          padding-bottom: 50px;
        `}

        ${minBreakpointQuery.smedium`
          padding-right: 60px;
          padding-left: 60px;
        `}

        ${minBreakpointQuery.large`
          padding-right: 80px;
          padding-left: 80px;
        `}

        ${minBreakpointQuery.xlarge`
          padding-right: 100px;
          padding-left: 100px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-right: 140px;
          padding-left: 140px;
        `}

        ${minBreakpointQuery.xxxlarge`
          padding-right: 180px;
          padding-left: 180px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-right: 220px;
          padding-left: 220px;
        `}
      `;
    }
  }}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}

  ${({ version }) => {
    if (version === 2) {
      return css`
        ${minBreakpointQuery.small`
          ${fontSize(20)};
        `}

        ${minBreakpointQuery.large`
          ${fontSize(23)};
        `}

        ${minBreakpointQuery.xxlarge`
          ${fontSize(26)};
        `}
      `;
    }
  }}
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  color: ${brandColours.tertiary};
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const ImageContent = ({
  image,
  heading,
  text,
  flip,
  version,
  isPaperSamples,
  paperSamplesName,
  locale,
}) => {
  const { togglePaperSamplesOpen } = useContext(ProductContext);

  return (
    <StyledImageContent version={version}>
      <StyledContainer version={version} wide={version === 2}>
        <StyledInner version={version}>
          <StyledImage
            image={image.gatsbyImageData}
            alt={image.alt}
            flip={flip}
          />
          <StyledContent version={version}>
            <Heading>{heading}</Heading>
            {text && <StyledText html={text} version={version} />}
            {isPaperSamples && (
              <StyledButton onClick={togglePaperSamplesOpen}>
                {locale
                  ? translateString('samples.orderSamples', locale).replace(
                      '[coolName]',
                      paperSamplesName
                    )
                  : `Order '{paperSamplesName}' Samples`}
              </StyledButton>
            )}
          </StyledContent>
        </StyledInner>
      </StyledContainer>
    </StyledImageContent>
  );
};

export default ImageContent;
