import React, { useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container } from './ui';
import Price from './Price';
import ProductOptions from './ProductOptions';
import ProductForm from './ProductForm';
import RollCalculator from './RollCalculator';
import CroppingTool from './CroppingTool';
import PaperSamples from './PaperSamples';
import { checkIfMural, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';
import { ProductContext } from './Product';

const StyledProductSummary = styled.section``;

const StyledNamePrice = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StyledName = styled.div``;

const StyledFriendlyName = styled.p`
  color: ${brandColours.secondary};
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledDescriptiveName = styled.h1`
  margin-top: 4px;
  color: ${standardColours.darkerGrey};
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};
  line-height: 1.4;

  ${minBreakpointQuery.small`
    margin-top: 5px;
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 6px;
    ${fontSize(18)};
  `}
`;

const StyledPrice = styled(Price)`
  margin: 10px 0 0 10px;
  ${fontSize(14)};
  white-space: nowrap;

  ${minBreakpointQuery.small`
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}

  span {
    font-weight: ${fontWeights.bold};
    ${fontSize(16)};

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(20)};
    `}

    ${minBreakpointQuery.xxlarge`
      ${fontSize(22)};
    `}
  }
`;

const StyledCalculatorButton = styled.button`
  display: block;
  margin: 20px auto;
  background: none;
  border: none;

  span {
    color: ${brandColours.primary};
    text-decoration: underline;
  }
`;

const StyledIconPoints = styled.ul`
  margin-top: 22px;

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
  `}
`;

const StyledIconPoint = styled.li`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: ${fontWeights.regular};
  ${fontSize(14)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledIconPointIcon = styled.img`
  margin-right: 10px;
  height: 22px;
  width: 22px;
`;

const ProductSummary = ({
  title,
  descriptiveName,
  options,
  variants,
  sampleVariants,
  selectedVariantImages,
  getVariant,
  paperTypes,
  rollWidth,
  rollHeight,
  verticalPatternRepeat,
  excessMessaging,
  iconPoints,
  locale,
  ...props
}) => {
  const {
    datoCmsProductSitewide: {
      minGbp,
      minUsd,
      minCad,
      minAud,
      minNzd,
      minEur,
      minHkd,
      minChf,
      minAed,
      minSar,
    },
  } = useStaticQuery(graphql`
    query ProductSummaryQuery {
      datoCmsProductSitewide {
        minGbp
        minUsd
        minCad
        minAud
        minNzd
        minEur
        minHkd
        minChf
        minAed
        minSar
      }
    }
  `);

  const { setOverlayActive, currency } = useContext(StoreContext);
  const {
    selectedVariant,
    setSelectedVariant,
    selectedSampleVariant,
    setSelectedSampleVariant,
  } = useContext(ProductContext);

  const isMural = checkIfMural(selectedVariant.sku);

  const [isRollCalculatorOpen, setRollCalculatorOpen] = useState();

  const [cropImage, setCropImage] = useState('');
  const [croppingMode, setCroppingMode] = useState(false);

  const minOrderValues = {
    GBP: minGbp,
    USD: minUsd,
    CAD: minCad,
    AUD: minAud,
    NZD: minNzd,
    EUR: minEur,
    HKD: minHkd,
    CHF: minChf,
    AED: minAed,
    SAR: minSar,
  };

  useEffect(() => {
    selectedVariantImages &&
      selectedVariantImages.mainImages.length > 0 &&
      setCropImage(
        selectedVariantImages.mainImages[1] &&
          selectedVariantImages.mainImages[1].url
          ? selectedVariantImages.mainImages[1].url.replace(
              '.jpg?',
              '.jpg?w=1680&'
            )
          : selectedVariantImages.mainImages[0].src.replace(
              '.jpg',
              '_1680x.jpg'
            )
      );
  }, [selectedVariantImages]);

  const handleChange = (name, value, i) => {
    const variant = getVariant(variants, {
      ...selectedVariant.selectedOptions,
      [i]: {
        name,
        value,
      },
    });
    setSelectedVariant(variant);

    const sampleVariant = getVariant(sampleVariants, {
      ...selectedSampleVariant.selectedOptions,
      [i]: {
        name,
        value,
      },
    });

    // Don't allow the Self Adhesive sample to be chosen
    if (
      !sampleVariant.title.includes('Self Adhesive') &&
      !sampleVariant.title.includes('Auto-adhésif')
    ) {
      setSelectedSampleVariant(sampleVariant);
    }
  };

  const toggleRollCalculatorOpen = () => {
    setOverlayActive(!isRollCalculatorOpen);
    setRollCalculatorOpen(!isRollCalculatorOpen);
  };

  const toggleCroppingModeOpen = () => {
    setOverlayActive(!croppingMode);
    setCroppingMode(!croppingMode);
  };

  return (
    <StyledProductSummary {...props}>
      <Container>
        <StyledNamePrice>
          <StyledName>
            {descriptiveName ? (
              <>
                <StyledFriendlyName>{title}</StyledFriendlyName>
                <StyledDescriptiveName>{descriptiveName}</StyledDescriptiveName>
              </>
            ) : (
              <StyledDescriptiveName>{title}</StyledDescriptiveName>
            )}
          </StyledName>
          <StyledPrice variant={selectedVariant} locale={locale} />
        </StyledNamePrice>
        <ProductOptions
          options={options}
          handleChange={handleChange}
          variants={variants}
          getVariant={getVariant}
          selectedVariant={selectedVariant}
          paperTypes={paperTypes}
          isMural={isMural}
          unit={currency === 'USD' ? 'in' : 'cm'}
          locale={locale}
        />
        <ProductForm
          toggleRollCalculatorOpen={toggleRollCalculatorOpen}
          toggleCroppingModeOpen={toggleCroppingModeOpen}
          locale={locale}
          isMural={isMural}
        />
        {!isMural ? (
          <>
            <StyledCalculatorButton onClick={toggleRollCalculatorOpen}>
              {translateString('product.rollsQuestion', locale)}{' '}
              <span>{translateString('product.rollCalculator', locale)}</span>
            </StyledCalculatorButton>
            <RollCalculator
              rollHeight={rollHeight}
              rollWidth={rollWidth}
              patternLength={verticalPatternRepeat}
              locale={locale}
              isRollCalculatorOpen={isRollCalculatorOpen}
              toggleRollCalculatorOpen={toggleRollCalculatorOpen}
            />
          </>
        ) : (
          <CroppingTool
            image={cropImage}
            croppingMode={croppingMode}
            toggleCroppingModeOpen={toggleCroppingModeOpen}
            options={options}
            handleChange={handleChange}
            variants={variants}
            getVariant={getVariant}
            selectedVariant={selectedVariant}
            paperTypes={paperTypes}
            selectedUnit={currency === 'USD' ? 'in' : 'cm'}
            minOrderValues={minOrderValues}
            locale={locale}
            excessMessaging={excessMessaging}
          />
        )}
        <PaperSamples
          options={options}
          handleChange={handleChange}
          variants={variants}
          getVariant={getVariant}
          selectedVariant={selectedVariant}
          paperTypes={paperTypes}
          locale={locale}
        />
        {iconPoints.length > 0 && (
          <StyledIconPoints>
            {iconPoints.map(({ icon, text }) => (
              <StyledIconPoint>
                <StyledIconPointIcon
                  src={icon.url}
                  alt={icon.alt}
                  loading="lazy"
                />
                {text}
              </StyledIconPoint>
            ))}
          </StyledIconPoints>
        )}
      </Container>
    </StyledProductSummary>
  );
};

export default ProductSummary;
