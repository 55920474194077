import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  fontSize,
} from '../styles';
import { Container, Heading, Button } from './ui';

const StyledBackgroundImageCta = styled.section`
  position: relative;
`;

const StyledBackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledContent = styled.div`
  position: relative;
  ${sectionPaddings('80px', '220px')};
  color: ${standardColours.white};
  text-align: center;
  background-color: ${standardColours.transparentBlack(0.4)};
`;

const StyledText = styled.p`
  margin-top: 15px;
  line-height: 1.7;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const BackgroundImageCta = ({ backgroundImage, heading, text, link }) => (
  <StyledBackgroundImageCta>
    <StyledBackgroundImage
      image={backgroundImage.gatsbyImageData}
      alt={backgroundImage.alt}
    />
    <StyledContent>
      <Container narrow={true}>
        <Heading>{heading}</Heading>
        {text && <StyledText>{text}</StyledText>}
        <StyledButton to={link.page} alt={true}>
          {link.text}
        </StyledButton>
      </Container>
    </StyledContent>
  </StyledBackgroundImageCta>
);

export default BackgroundImageCta;
