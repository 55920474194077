import React, { useContext, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  inputStyles,
  standardColours,
  brandColours,
  visuallyHidden,
  fontSize,
} from '../styles';
import { Button } from './ui';
import PopOutSidebar, {
  StyledContent,
  StyledFooter,
  StyledSummaryItem,
} from './PopOutSidebar';
import { useVariantPrice, formatPrice, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';
import { ProductContext } from './Product';
import sidesArrowIcon from '../images/sides-arrow-icon.svg';

const StyledRollCalculator = styled(PopOutSidebar)`
  right: 0;
`;

const StyledInner = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledText = styled.p`
  line-height: 1.5;
`;

const StyledCalculator = styled.div`
  margin-top: 20px;
`;

const StyledUnitSelector = styled.div``;

const StyledUnits = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const StyledLegend = styled.legend`
  margin-bottom: 14px;
`;

const StyledRadioLabel = styled.label`
  position: relative;
  display: inline-flex;
  margin-left: 5px;
  padding-left: 32px;
  height: 26px;
  align-items: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${standardColours.white};
    border: 3px solid ${standardColours.black};
  }

  &:after {
    left: 7px;
    top: 7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${standardColours.white};
  }
`;

const StyledRadioInput = styled.input`
  ${visuallyHidden()};

  &:checked {
    + ${StyledRadioLabel} {
      &:after {
        background-color: ${standardColours.black};
      }
    }
  }
`;

const StyledCmInputs = styled.fieldset`
  margin: 32px 0 0;
  padding: 0;
  border: none;
`;

const StyledInputWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  &:after {
    content: '';
    margin-left: 10px;
    min-height: 56px;
    min-width: 56px;
    background: url('${sidesArrowIcon}') no-repeat 50% 50% / 50%
      ${brandColours.tertiary};
    border-radius: 50%;

    ${({ alt }) => {
      if (alt) {
        return css`
          transform: rotate(90deg);
        `;
      }
    }}
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledInput = styled.input`
  ${inputStyles()};
  height: 55px;
  border-radius: 5px;
  outline: 2px solid ${standardColours.black};
`;

const StyledRollItemSpan = styled.span`
  margin-right: 30px;
`;

const StyledRollErrorMessage = styled.span`
  ${fontSize(12)}
`;

const StyledButton = styled(Button)`
  width: 100%;
  color: ${standardColours.black};
  background-color: ${standardColours.transparentWhite(0.6)};
  border-color: ${standardColours.transparentWhite(0.6)};

  &:hover {
    color: ${standardColours.white};
    background-color: ${standardColours.black};
    border-color: ${standardColours.black};
  }
`;

const RollCalculator = ({
  rollHeight,
  rollWidth,
  patternLength,
  locale,
  isRollCalculatorOpen,
  toggleRollCalculatorOpen,
}) => {
  const { addProductsToCheckout, currency } = useContext(StoreContext);
  const { selectedVariant } = useContext(ProductContext);

  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [numberOfRolls, setNumberOfRolls] = useState(0);
  const [unit, setUnit] = useState('cm');
  const [autoSelectUnit, setAutoSelectUnit] = useState(true);
  const [unitConversion, setUnitConversion] = useState(1);

  const variantPrice = useVariantPrice(selectedVariant);

  useEffect(() => {
    if (autoSelectUnit) {
      setUnit(currency === 'USD' ? 'inches' : 'cm');
      setUnitConversion(currency === 'USD' ? 2.54 : 1);
    }

    if (height && width) {
      // Inputs
      const RollHeight = rollHeight; // roll height;
      const RollWidth = rollWidth; // roll width;
      const PatternLength = JSON.parse(patternLength).value * 10; // convert cm to mm
      const WallHeight = height * 10 * unitConversion; // convert cm to mm
      const WallWidth = width * 10 * unitConversion; // convert cm to mm

      // Setup
      const NoD = Math.ceil(WallWidth / RollWidth);

      let NoR = 0;
      let W_rem = WallHeight;
      let cut = 0;
      let CurrentDrop = 1;
      let OpenNewRoll = 1;
      let HeadFragment = 0;
      let P_rem = 0;

      if (
        (WallHeight <= RollHeight - PatternLength / 2 && NoD > 1) ||
        (WallHeight <= rollHeight && NoD === 1)
      ) {
        do {
          if (OpenNewRoll === 1) {
            NoR = NoR + 1;
            OpenNewRoll = 0;
            P_rem = RollHeight;
          }
          if (CurrentDrop % 2 === 0) {
            if (HeadFragment > PatternLength / 2) {
              cut = HeadFragment - PatternLength / 2;
            } else if (HeadFragment < PatternLength / 2) {
              cut = HeadFragment + PatternLength / 2;
            } else {
              cut = 0;
            }
          } else {
            cut = HeadFragment;
          }

          if (P_rem - cut >= W_rem) {
            P_rem = P_rem - W_rem - cut;
            HeadFragment =
              PatternLength - ((RollHeight - P_rem) % PatternLength);
            if (CurrentDrop >= NoD) {
              break;
            } else {
              CurrentDrop = CurrentDrop + 1;
              W_rem = WallHeight;
            }
          } else {
            W_rem = WallHeight;
            P_rem = 0;
            HeadFragment = 0;
            OpenNewRoll = 1;
          }
        } while (true);
      } else {
        NoR = -1;
      }

      setNumberOfRolls(NoR);
    }
  }, [rollHeight, rollWidth, patternLength, height, width, unit, currency]);

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: numberOfRolls,
      },
    ];

    await addProductsToCheckout(lineItems);

    setHeight();
    setWidth();
    setNumberOfRolls(0);

    toggleRollCalculatorOpen();
  };

  return (
    <StyledRollCalculator
      heading={translateString('rollCalculator.heading', locale)}
      isOpen={isRollCalculatorOpen}
      toggleOpen={toggleRollCalculatorOpen}
    >
      <StyledInner onSubmit={e => handleSubmit(e)}>
        <StyledContent>
          <StyledText>
            {translateString('rollCalculator.intro', locale)}
          </StyledText>
          <StyledCalculator>
            <StyledUnitSelector>
              <StyledUnits>
                <StyledLegend>
                  {translateString('rollCalculator.measureText', locale)}
                </StyledLegend>
                <StyledRadioInput
                  type="radio"
                  id="cm-unit"
                  name="unit"
                  value="cm"
                  checked={unit === 'cm' ? true : false}
                  required
                  onChange={() => {
                    setAutoSelectUnit(false);
                    setUnit('cm');
                    setUnitConversion(1);
                  }}
                />
                <StyledRadioLabel htmlFor="cm-unit">
                  Centimeters
                </StyledRadioLabel>
                <StyledRadioInput
                  type="radio"
                  id="inches-unit"
                  name="unit"
                  value="inches"
                  checked={unit === 'inches' ? true : false}
                  required
                  onChange={() => {
                    setAutoSelectUnit(false);
                    setUnit('inches');
                    setUnitConversion(2.54);
                  }}
                />{' '}
                <StyledRadioLabel htmlFor="inches-unit">
                  Inches
                </StyledRadioLabel>
              </StyledUnits>
            </StyledUnitSelector>
            <StyledCmInputs>
              <StyledInputWrapper alt={true}>
                <span>
                  {translateString('rollCalculator.heightPlaceholder', locale)}{' '}
                  (in cm)
                </span>
                <StyledInput
                  type="number"
                  name="wall-height"
                  placeholder={`${translateString(
                    'rollCalculator.heightPlaceholder',
                    locale
                  )} (in ${unit})`}
                  value={height}
                  required
                  onChange={e => setHeight(e.target.value)}
                />
              </StyledInputWrapper>
              <StyledInputWrapper>
                <span>
                  {translateString('rollCalculator.widthPlaceholder', locale)}{' '}
                  (in cm)
                </span>
                <StyledInput
                  type="number"
                  name="wall-width"
                  placeholder={`${translateString(
                    'rollCalculator.widthPlaceholder',
                    locale
                  )} (in ${unit})`}
                  value={width}
                  required
                  onChange={e => setWidth(e.target.value)}
                />
              </StyledInputWrapper>
            </StyledCmInputs>
          </StyledCalculator>
        </StyledContent>
        <StyledFooter>
          <StyledSummaryItem>
            <span>{translateString('rollCalculator.dimensions', locale)}</span>{' '}
            {unit === 'cm'
              ? (height > 0 ? height / 100 : 0) +
                'm x ' +
                (width > 0 ? width / 100 : 0) +
                'm'
              : (height > 0
                  ? parseInt(height / 12) + "'" + Math.round(height % 12, 1)
                  : "0'0") +
                ' x ' +
                (width > 0
                  ? parseInt(width / 12) + "'" + Math.round(width % 12, 1)
                  : "0'0")}
          </StyledSummaryItem>
          <StyledSummaryItem>
            <StyledRollItemSpan>
              {translateString('rollCalculator.rolls', locale)}
            </StyledRollItemSpan>{' '}
            {numberOfRolls !== -1 ? (
              numberOfRolls
            ) : (
              <StyledRollErrorMessage>
                {translateString('rollCalculator.errorMessage', locale)}
              </StyledRollErrorMessage>
            )}
          </StyledSummaryItem>
          <StyledSummaryItem>
            <span>{translateString('rollCalculator.subtotal', locale)}</span>
            {variantPrice &&
              formatPrice({
                amount: variantPrice.amount * numberOfRolls,
                currencyCode: variantPrice.currencyCode,
                locale: locale,
              })}
          </StyledSummaryItem>
          <StyledButton>
            {translateString('product.addToCart', locale)}
          </StyledButton>
        </StyledFooter>
      </StyledInner>
    </StyledRollCalculator>
  );
};

export default RollCalculator;
