import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { Container, NextArrow, PreviousArrow, Svg } from './ui';
import { translateString } from '../utils';
import starIcon from '../images/star.inline.svg';

const StyledProductReviews = styled.section`
  ${minBreakpointQuery.small`
    position: relative;
  `}
`;

const StyledHeading = styled.h3`
  text-align: center;
  ${fontSize(16)}

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)}
  `}
`;

const StyledItems = styled.div`
  padding: 70px 15px;
  background-color: ${brandColours.senary};
`;

const StyledSwiper = styled(Swiper)`
  max-width: 500px;
`;

const StyledItem = styled.div`
  text-align: center;

  ${minBreakpointQuery.small`
    padding-left: 25px;
    padding-right: 25px;
  `}
`;

const StyledText = styled.p`
  margin-top: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
  ${fontSize(14)}

  ${minBreakpointQuery.mlarge`
    ${fontSize(16)}
  `}
`;

const StyledAuthor = styled.span`
  font-weight: ${fontWeights.semibold};
  ${fontSize(16)}

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)}
  `}
`;

const StyledStars = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: center;
  gap: 4px;

  ${minBreakpointQuery.small`
    margin-top: 40px;
  `}
`;

const StyledStar = styled(Svg)`
  width: 18px;
  height: 18px;

  polygon {
    fill: ${({ alt }) =>
      alt ? standardColours.darkGrey : standardColours.black};
  }
`;

const StyledArrows = styled.div`
  display: flex;
  justify-content: center;

  ${maxBreakpointQuery.small`
    margin-top: 16px;
    margin-bottom: 16px;
  `}

  ${minBreakpointQuery.small`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    width: 100%;
    justify-content: space-between;
    max-width: ${breakpointSizes.xlarge}px;
  `}

  .swiper-button-lock {
    display: none;
  }
`;

const StyledPrevArrow = styled(PreviousArrow)`
  &:first-child {
    ${maxBreakpointQuery.xlarge`
      margin-left: 30px;
    `}
  }
`;

const StyledNextArrow = styled(NextArrow)`
  ${maxBreakpointQuery.xlarge`
    margin-right: 30px;
  `}
`;

const ProductReviews = ({ product, items, locale }) => {
  const prevEl = useRef(null);
  const nextEl = useRef(null);

  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    onInit: swiper => {
      swiper.params.navigation.prevEl = [prevEl.current.base];
      swiper.params.navigation.nextEl = [nextEl.current.base];
      swiper.navigation.prevEl = [prevEl.current.base];
      swiper.navigation.nextEl = [nextEl.current.base];
      swiper.navigation.init();
      swiper.navigation.update();
    },
  };

  return (
    <StyledProductReviews id="reviews">
      <StyledItems>
        <Container>
          <StyledHeading>
            {translateString('product.reviewIntro', locale)} {product}
          </StyledHeading>
          <StyledSwiper {...sliderOptions}>
            {items.map(({ author, text, score }, id) => (
              <SwiperSlide key={id}>
                <StyledItem>
                  <StyledText>
                    {text} <StyledAuthor>{author}.</StyledAuthor>
                  </StyledText>
                  <StyledStars>
                    {[...Array(5)].map((_, i) => (
                      <StyledStar
                        key={i}
                        image={starIcon}
                        alt={i + 1 > score}
                      />
                    ))}
                  </StyledStars>
                </StyledItem>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Container>
      </StyledItems>
      <StyledArrows>
        <StyledPrevArrow ref={prevEl} version={2}>
          <span>Previous</span>
        </StyledPrevArrow>
        <StyledNextArrow alt={true} ref={nextEl} version={2}>
          <span>Next</span>
        </StyledNextArrow>
      </StyledArrows>
    </StyledProductReviews>
  );
};

export default ProductReviews;
