import React from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  sectionMargins,
  fontWeights,
  fontSize,
  fluidFontSize,
  formGridStyles,
  labelStyles,
  labelTextStyles,
  inputStyles,
  formButtonStyles,
  minBreakpointQuery,
  standardColours,
  maxBreakpointQuery,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Button } from './ui';

const StyledTradeForm = styled.section`
  ${({ isTradeSignUp }) => {
    if (!isTradeSignUp) {
      return css`
        ${sectionMargins()};
      `;
    }
  }}

  ${maxBreakpointQuery.mlarge`
    text-align: center;
  `}
`;

const StyledContainer = styled(Container)`
  ${({ isTradeSignUp }) => {
    if (isTradeSignUp) {
      return css`
        padding-right: 0;
        padding-left: 0;
      `;
    }
  }}
`;

const StyledInner = styled.div`
  padding: 20px;
  border: 1px solid ${standardColours.lightGrey};

  ${minBreakpointQuery.small`
    padding: 30px;
  `}
`;

const StyledHeading = styled(Heading)`
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledText = styled.p`
  font-weight: ${fontWeights.regular};
  ${fontSize(13)};
  line-height: 1.7;
  margin-top: 10px;

  ${minBreakpointQuery.medium`
    ${fontSize(14)};
  `}
`;

const StyledForm = styled.form`
  ${formGridStyles()}
`;

const StyledLabel = styled.label`
  ${labelStyles()};

  &:nth-child(6) {
    grid-column-start: 1;
  }
`;

const StyledLabelText = styled.span`
  ${labelTextStyles()}
`;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledCheckboxWrapper = styled.div`
  grid-column: 1 / 3;
  margin: 10px 0;
`;

const StyledCheckboxInput = styled.input`
  ${visuallyHidden()};

  &:checked {
    + label {
      &:after {
        background-color: ${standardColours.black};
      }
    }
  }
`;

const StyledCheckboxLabel = styled.label`
  position: relative;
  display: flex;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${standardColours.black};
  }

  &:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${standardColours.white};
  }
`;

const StyledCheckboxText = styled.span`
  margin-left: 30px;
  ${fontSize(13)};
  line-height: 1.5;

  ${minBreakpointQuery.medium`
    ${fontSize(14)};
  `}
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const CreateTradeAccountForm = ({ heading, text, isTradeSignUp }) => {
  const submitForm = event => {
    event.preventDefault();
    const accountForm = document.getElementById('create-trade-account');
    const formData = new FormData(accountForm);

    fetch('/.netlify/functions/createTradeAccount', {
      method: 'POST',
      body: JSON.stringify({
        data: JSON.stringify(new URLSearchParams(formData).toString()),
      }),
    })
      .then(() => {
        fetch('/.netlify/functions/sendMail', {
          method: 'POST',
          body: JSON.stringify({
            data: JSON.stringify(new URLSearchParams(formData).toString()),
          }),
        })
          .then(() => {
            navigate('/thank-you-for-your-trade-application/');
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  };

  return (
    <StyledTradeForm isTradeSignUp={isTradeSignUp}>
      <StyledContainer narrow={true} isTradeSignUp={isTradeSignUp}>
        <StyledInner>
          <StyledHeading>{heading}</StyledHeading>
          {text && <StyledText>{text}</StyledText>}
          <StyledForm
            id="create-trade-account"
            name="create-trade-account"
            method="POST"
            onSubmit={submitForm}
          >
            <StyledLabel>
              <StyledLabelText>First name *</StyledLabelText>
              <StyledInput type="text" name="first-name" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Last name *</StyledLabelText>
              <StyledInput type="text" name="last-name" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Job title *</StyledLabelText>
              <StyledInput type="text" name="job-title" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Email address *</StyledLabelText>
              <StyledInput type="email" name="email-address" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Phone number *</StyledLabelText>
              <StyledInput type="tel" name="phone-number" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Company name *</StyledLabelText>
              <StyledInput type="text" name="company-name" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Company website *</StyledLabelText>
              <StyledInput type="text" name="company-website" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Create password *</StyledLabelText>
              <StyledInput type="password" name="password" required />
            </StyledLabel>
            <StyledCheckboxWrapper>
              <StyledCheckboxInput
                type="checkbox"
                id="allow-updates"
                name="allow-updates"
              />
              <StyledCheckboxLabel htmlFor="allow-updates">
                <StyledCheckboxText>
                  Tick if you would like updates about new products &amp;
                  previews from Bobbi Beck
                </StyledCheckboxText>
              </StyledCheckboxLabel>
            </StyledCheckboxWrapper>
            <StyledSubmit>Request a trade account</StyledSubmit>
          </StyledForm>
        </StyledInner>
      </StyledContainer>
    </StyledTradeForm>
  );
};

export default CreateTradeAccountForm;
