import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
} from '../styles';
import { Container, HtmlContent, Button } from './ui';

const StyledContent = styled.section`
  ${sectionMargins('30px', '60px')};
`;

const StyledContainer = styled(Container)`
  max-width: ${({ narrow }) => (narrow ? '680' : '900')}px;
`;

const StyledInner = styled.div`
  ${({ twoColumns }) => {
    if (twoColumns) {
      return css`
        ${minBreakpointQuery.mlarge`
          column-count: 2;
          column-gap: 60px;
        `}

        ${minBreakpointQuery.large`
          column-gap: 80px;
        `}

        ${minBreakpointQuery.xxlarge`
          column-gap: 100px;
        `}
      `;
    }
  }};

  ${({ highlight }) => {
    if (highlight) {
      return css`
        padding: 30px;
        color: ${standardColours.white};
        background-color: ${brandColours.primary};

        ${minBreakpointQuery.small`
          padding: 40px;
        `}

        ${minBreakpointQuery.large`
          padding: 60px;
        `}
      `;
    }
  }};
`;

const StyledFooter = styled.footer`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Content = ({ html, buttonLink, twoColumns, highlight, contain }) => (
  <StyledContent>
    <StyledContainer narrow={contain}>
      <StyledInner twoColumns={twoColumns} highlight={highlight}>
        <HtmlContent html={html} />
      </StyledInner>
      {buttonLink && (
        <StyledFooter>
          <StyledButton to={buttonLink.page}>{buttonLink.text}</StyledButton>
        </StyledFooter>
      )}
    </StyledContainer>
  </StyledContent>
);

export default Content;
