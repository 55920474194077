import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';
import { getSingleLocaleFields } from '../utils';

const StyledTestimonials = styled.section`
  ${sectionPaddings('30px', '60px')};
  border-top: 1px solid ${standardColours.lighterGrey};
  border-bottom: 1px solid ${standardColours.lighterGrey};
`;

const StyledContainer = styled(Container)`
  max-width: 660px;
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div``;

const StyledItem = styled.blockquote`
  margin: 0;
  color: ${brandColours.primary};
  text-align: center;
`;

const StyledHeading = styled.h3`
  ${fontSize(16)};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledText = styled.div`
  p {
    margin-top: 12px;
    ${fontSize(14)};
    line-height: 1.5;

    ${minBreakpointQuery.small`
      margin-top: 14px;
      ${fontSize(16)};
    `}

    ${minBreakpointQuery.large`
      margin-top: 16px;
      ${fontSize(18)};
    `}
  }
`;

const StyledBadge = styled.img`
  display: block;
  margin: 15px auto auto;
  max-width: 200px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
    max-width: 220px;
  `}

  ${minBreakpointQuery.large`
     margin-top: 25px;
     max-width: 240px;
  `}
`;

const Testimonials = ({ items: testimonials, locale }) => {
  const { allDatoCmsTestimonialsSitewide } = useStaticQuery(graphql`
    query TestimonialsQuery {
      allDatoCmsTestimonialsSitewide {
        nodes {
          locale
          testimonials {
            id
            heading
            text
          }
          badge {
            url
            alt
          }
        }
      }
    }
  `);

  const { testimonials: testimonialsSitewide, badge } = getSingleLocaleFields(
    allDatoCmsTestimonialsSitewide,
    locale
  );

  const items =
    (testimonials.length > 0 && testimonials) ||
    (testimonialsSitewide.length > 0 && testimonialsSitewide);

  const [options, setOptions] = useState({});
  const [sliderRef] = useKeenSlider(options, [
    slider => {
      let timeout;
      let mouseOver = false;

      function clearNextTimeout() {
        clearTimeout(timeout);
      }

      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 4000);
      }

      slider.on('created', () => {
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener('mouseout', () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });

      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
  ]);

  useEffect(() => {
    setOptions({
      loop: true,
      slides: { perView: 1 },
    });
  }, []);

  return (
    items.length > 0 && (
      <StyledTestimonials>
        <StyledContainer>
          <StyledHiddenHeading>Testimonials</StyledHiddenHeading>
          <StyledItems ref={sliderRef} className="keen-slider">
            {items.map(({ id, heading, text }) => (
              <StyledItem key={id} className="keen-slider__slide">
                <StyledHeading>"{heading}"</StyledHeading>
                <StyledText dangerouslySetInnerHTML={{ __html: text }} />
              </StyledItem>
            ))}
          </StyledItems>
          {badge && (
            <StyledBadge src={badge.url} alt={badge.alt} loading="lazy" />
          )}
        </StyledContainer>
      </StyledTestimonials>
    )
  );
};

export default Testimonials;
