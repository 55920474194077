import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
  zIndexLayers,
} from '../styles';
import { Link, Svg } from './ui';
import {
  formatPrice,
  getColourHex,
  translateString,
  useVariantPrice as getVariantPrice,
} from '../utils';
import { StoreContext } from '../context/StoreContext';
import starIcon from '../images/star.inline.svg';
import infoIcon from '../images/info-icon.inline.svg';

const StyledProductOptions = styled.div`
  ${minBreakpointQuery.mlarge`
    z-index: ${zIndexLayers.first};
  `}
`;

const StyledOptions = styled.div`
  ${({ addPaddingTop }) => {
    if (addPaddingTop) {
      return css`
        padding-top: 16px;
      `;
    }
  }}

  &:not(:first-child) {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${standardColours.lightGrey};

    ${minBreakpointQuery.tsmall`
      margin-top: 24px;
      padding-top: 24px;
    `}
  }
`;

const StyledHeading = styled.span`
  font-weight: ${fontWeights.semibold};
  ${fontSize(14)};
  color: ${brandColours.primary};

  ${minBreakpointQuery.tsmall`
    ${fontSize(16)};
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledRadioOptions = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  margin-top: 14px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  ${({ isPaperType }) => {
    if (isPaperType) {
      return css`
        flex-direction: column;
      `;
    }
  }}
`;

const StyledColourOption = styled.label`
  position: relative;
  cursor: pointer;
  height: 24px;

  ${({ variantColour }) => {
    if (variantColour) {
      return css`
        &:before {
          content: '';
          display: block;
          height: 24px;
          width: 24px;
          background-color: ${({ variantColour }) =>
            variantColour
              ? variantColour
              : standardColours.transparentBlack(0.5)};
          border-radius: 50%;
          border: 1px solid ${standardColours.lightGrey};
        }
      `;
    }
  }}

  ${({ isCustom }) => {
    if (isCustom) {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 24px;
        width: 24px;
        border: 2px solid ${standardColours.darkerGrey};
        border-radius: 50%;
      `;
    }
  }}

  ${({ active }) => {
    if (active) {
      return css`
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          display: block;
          height: 34px;
          width: 34px;
          border-radius: 50%;
          border: 1px solid ${standardColours.grey};
        }
      `;
    }
  }}

  span {
    ${({ isCustom }) => {
      if (!isCustom) {
        return css`
          ${visuallyHidden()}
        `;
      } else {
        return css`
          display: block;
          width: 14px;
          height: 14px;
          text-align: center;
          line-height: 14px;
          ${fontSize(14)}
          color: ${standardColours.darkerGrey};
          font-weight: ${fontWeights.semibold};
        `;
      }
    }}
  }
`;

const StyledWallDimensionsIntro = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtons = styled.div`
  display: flex;
  background-color: ${standardColours.lighterGrey};
  border-radius: 20px;
  padding: 6px;
`;

const StyledWallDimensionsInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  margin-top: 15px;
`;

const StyledWallDimensionsInput = styled.input`
  padding: 14px 14px 14px 16px;
  line-height: 1.4;
  border: none;
  width: 100%;
  ${fontSize(12)}
  font-weight: ${fontWeights.medium};
  background-color: ${standardColours.lighterGrey};

  &:focus {
    ${maxBreakpointQuery.smedium`
      ${fontSize(16)}
    `}
  }
`;

const StyledButton = styled.button`
  color: ${standardColours.black};
  border: none;
  ${fontSize(12)}
  font-weight: ${fontWeights.semibold};
  border-radius: 20px;
  padding: 4px 10px;
  width: 38px;
  height: 26px;
  background: none;

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${standardColours.white};
        box-shadow: 0px 2px 4px 0px ${standardColours.transparentBlack(0.08)};
      `;
    }
  }}
`;

const StyledExcessMessaging = styled.p`
  margin-top: 10px;
  ${fontSize(12)}
  color: ${standardColours.darkerGrey};
`;

const StyledPaperTypeOption = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  ${fontSize(12)};
  padding-left: 24px;
  line-height: 20px;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${brandColours.primary};

    ${minBreakpointQuery.tsmall`
      width: 18px;
      height: 18px;
    `}
  }

  &:after {
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ active }) =>
      active ? brandColours.primary : standardColours.white};

    ${minBreakpointQuery.tsmall`
      width: 10px;
      height: 10px;
    `}
  }
`;

const StyledRadio = styled.input`
  ${visuallyHidden()}
`;

const StyledPopular = styled.span`
  display: inline-block;
`;

const StyledIcon = styled(Svg)`
  width: 14px;
  height: 14px;
  margin: 0 5px;
  vertical-align: sub;

  polygon {
    fill: #ffc82e;
  }
`;

const StyledPaperTypeInfo = styled.div`
  margin-top: 16px;
  display: flex;
  border: 1px solid ${standardColours.lightGrey};
  border-radius: 8px;
  padding: 12px;
`;

const StyledPaperTypeInfoIcon = styled(Svg)``;

const StyledPaperTypeInfoText = styled.span`
  ${fontSize(11)};
  color: ${standardColours.darkerGrey};
  margin-left: 8px;

  ${minBreakpointQuery.tsmall`
    ${fontSize(12)};
  `}
`;

const ProductOptionsV2 = ({
  options,
  getVariant,
  variants,
  selectedVariant,
  variantPrice,
  handleChange,
  isMural,
  isCustomColour,
  selectedUnit,
  minOrderValues,
  paperTypes,
  cropWidth,
  cropHeight,
  inputFocus,
  croppingMode,
  setTotal,
  setCropWidth,
  setCropHeight,
  setSelectedUnit,
  setInputFocus,
  setIsCustomColour,
  setOverlayActive,
  setCroppingMode,
  excessMessaging,
  isCroppingTool,
  locale,
}) => {
  const { currency, currencyLoaded } = useContext(StoreContext);

  const [paperTypeIndex, setPaperTypeIndex] = useState(0);

  const cropWidthInput = useRef();
  const cropHeightInput = useRef();

  const unitConversion = selectedUnit === 'cm' ? 10 : 1;

  const displayText =
    selectedUnit === 'cm'
      ? translateString('product.perM', locale)
      : translateString('product.perFt', locale);

  const colourOptions = options.find(({ name }) => name === 'Color');
  const paperTypeOptions = options.find(({ name }) => name === 'Paper Type');

  // Calculate min order value
  const minOrderQuanity = Math.round(
    minOrderValues[variantPrice.currencyCode] / variantPrice.amount
  );

  const minOrderValue = variantPrice.amount * minOrderQuanity;

  const calculatePrice = (width, height) => {
    // Calculate m2 quanity
    const m2 =
      ((width * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((height * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    // Min order value check
    width > 0 &&
      height > 0 &&
      setTotal(
        variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue
          ? minOrderValue
          : variantPrice.amount * (m2.toFixed(1) * 10)
      );
  };

  useEffect(() => {
    calculatePrice(cropWidth, cropHeight);
  }, [selectedUnit, variantPrice]);

  useEffect(() => {
    if (croppingMode) {
      if (inputFocus === 'crop-width') {
        cropWidthInput.current.focus();
      } else {
        cropHeightInput.current.focus();
      }
    }
  }, [croppingMode, inputFocus]);

  return (
    <StyledProductOptions>
      {colourOptions.values.length > 1 && (
        <StyledOptions addPaddingTop={isCroppingTool}>
          <StyledHeading>
            1. {translateString('product.select', locale)}{' '}
            {colourOptions.translations && colourOptions.translations.name
              ? colourOptions.translations.name.toLowerCase()
              : currency !== 'USD'
              ? 'Colour'
              : 'Color'}
            :{' '}
            {isCustomColour ? (
              <StyledLink to="/contact">{`Contact for custom ${
                currency !== 'USD' ? 'colour' : 'color'
              }`}</StyledLink>
            ) : (
              selectedVariant.selectedOptions.find(
                selectedOption => selectedOption.name === 'Color'
              ).value
            )}
          </StyledHeading>
          <StyledRadioOptions>
            {colourOptions.values.map((value, id) => (
              <StyledColourOption
                key={id}
                active={
                  !isCustomColour &&
                  selectedVariant.selectedOptions.find(
                    selectedOption => selectedOption.name === 'Color'
                  ).value === value
                }
                variantColour={getColourHex(
                  variants.find(
                    variant =>
                      variant.selectedOptions.find(
                        option =>
                          option.name === 'Color' && option.value === value
                      )?.value
                  ).metafields
                )}
              >
                <StyledRadio
                  type="radio"
                  name="Color"
                  value={value}
                  onChange={e => {
                    setIsCustomColour(false);
                    handleChange(
                      'Color',
                      e.currentTarget.value,
                      options.findIndex(({ name }) => name === 'Color')
                    );
                  }}
                />
                <span>
                  {colourOptions.translations
                    ? colourOptions.translations.values[id]
                    : value}
                </span>
              </StyledColourOption>
            ))}
            <StyledColourOption active={isCustomColour} isCustom={true}>
              <StyledRadio
                type="radio"
                name="Color"
                value="custom"
                onChange={() => setIsCustomColour(!isCustomColour)}
              />
              <span>?</span>
            </StyledColourOption>
          </StyledRadioOptions>
        </StyledOptions>
      )}
      {isMural && (
        <StyledOptions
          addPaddingTop={
            colourOptions.values.length > 1 ? false : isCroppingTool
          }
        >
          <StyledWallDimensionsIntro id="wall-dimensions">
            <StyledHeading>
              {colourOptions.values.length > 1 ? '2.' : '1.'}{' '}
              {translateString('croppingTool.enterDimensions', locale)}
            </StyledHeading>
            <StyledButtons>
              <StyledButton
                active={selectedUnit === 'cm'}
                onClick={e => {
                  e.preventDefault();
                  setSelectedUnit('cm');
                }}
              >
                <span>cm</span>
              </StyledButton>
              {locale !== 'fr' && (
                <StyledButton
                  active={selectedUnit === 'in'}
                  onClick={e => {
                    e.preventDefault();
                    setSelectedUnit('in');
                  }}
                >
                  <span>in</span>
                </StyledButton>
              )}
            </StyledButtons>
          </StyledWallDimensionsIntro>
          <StyledWallDimensionsInputs>
            <StyledWallDimensionsInput
              name="crop-width"
              ref={cropWidthInput}
              type="number"
              placeholder={`${translateString(
                'croppingTool.widthPlaceholder',
                locale
              )} ${selectedUnit ? `(${selectedUnit})` : ''}`}
              required={true}
              defaultValue={cropWidth}
              onFocus={() => {
                setOverlayActive && setOverlayActive(true);
                setCroppingMode && setCroppingMode(true);
                setInputFocus && setInputFocus('crop-width');
              }}
              onChange={e => {
                setCropWidth(e.target.value);
                calculatePrice(e.target.value, cropHeight);
              }}
            />
            <StyledWallDimensionsInput
              name="crop-height"
              ref={cropHeightInput}
              type="number"
              placeholder={`${translateString(
                'croppingTool.heightPlaceholder',
                locale
              )} ${selectedUnit ? `(${selectedUnit})` : ''}`}
              required={true}
              defaultValue={cropHeight}
              onFocus={() => {
                setOverlayActive && setOverlayActive(true);
                setCroppingMode && setCroppingMode(true);
                setInputFocus && setInputFocus('crop-height');
              }}
              onChange={e => {
                setCropHeight(e.target.value);
                calculatePrice(cropWidth, e.target.value);
              }}
            />
          </StyledWallDimensionsInputs>
          <StyledExcessMessaging>{excessMessaging}</StyledExcessMessaging>
        </StyledOptions>
      )}
      <StyledOptions>
        <StyledHeading>
          {colourOptions.values.length > 1
            ? isMural
              ? '3.'
              : '2.'
            : isMural
            ? '2.'
            : '1.'}{' '}
          {translateString('product.select', locale)}{' '}
          {paperTypeOptions.translations && paperTypeOptions.translations.name
            ? paperTypeOptions.translations.name.toLowerCase()
            : paperTypeOptions.name.toLowerCase()}
        </StyledHeading>
        <StyledRadioOptions isPaperType={true}>
          {paperTypeOptions.values.map((value, id) => {
            const variantOptions = {
              ...selectedVariant.selectedOptions,
              [options.findIndex(option => option.name === 'Paper Type')]: {
                name: 'Paper Type',
                value,
              },
            };

            const variant = getVariant(variants, variantOptions);

            if (!variant) {
              return;
            }

            const variantPrice = getVariantPrice(variant);

            return (
              <StyledPaperTypeOption
                key={id}
                active={
                  selectedVariant.selectedOptions.find(
                    selectedOption => selectedOption.name === 'Paper Type'
                  ).value === value
                }
                onChange={() => setPaperTypeIndex(id)}
              >
                <StyledRadio
                  type="radio"
                  name="WP-Paper Type"
                  value={value}
                  onChange={e =>
                    handleChange(
                      'Paper Type',
                      e.currentTarget.value,
                      options.findIndex(({ name }) => name === 'Paper Type')
                    )
                  }
                />
                <span>
                  {paperTypeOptions.translations
                    ? paperTypeOptions.translations.values[id]
                    : value === 'Self Adhesive'
                    ? 'Peel & Stick'
                    : value}
                  :{' '}
                  {currencyLoaded &&
                    formatPrice({
                      amount:
                        variantPrice.amount * (isMural ? unitConversion : 1),
                      currencyCode: variantPrice.currencyCode,
                      locale: locale,
                    })}{' '}
                  {isMural
                    ? displayText
                    : translateString('product.perRoll', locale)}
                  {isMural && <sup>2</sup>}{' '}
                  {value === 'Luxury' && (
                    <StyledPopular>
                      {' - '}
                      <StyledIcon image={starIcon} />
                      {translateString('product.popular', locale)}
                    </StyledPopular>
                  )}
                </span>
              </StyledPaperTypeOption>
            );
          })}
        </StyledRadioOptions>
        <StyledPaperTypeInfo>
          <StyledPaperTypeInfoIcon image={infoIcon} />
          <StyledPaperTypeInfoText>
            {
              paperTypes.find(({ name }) =>
                paperTypeOptions.translations
                  ? name ===
                    paperTypeOptions.translations.values[paperTypeIndex]
                  : name === paperTypeOptions.values[paperTypeIndex]
              )?.shortText
            }
          </StyledPaperTypeInfoText>
        </StyledPaperTypeInfo>
      </StyledOptions>
    </StyledProductOptions>
  );
};

export default ProductOptionsV2;
