import React from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  formGridStyles,
  labelStyles,
  fullLabelStyles,
  labelTextStyles,
  inputStyles,
  textAreaStyles,
  formButtonStyles,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Button } from './ui';

const StyledContactForm = styled.section`
  ${sectionMargins()}
`;

const StyledContainer = styled(Container)`
  max-width: 680px;
`;

const StyledForm = styled.form`
  ${formGridStyles()}
`;

const StyledLabel = styled.label`
  ${({ full, honeypot }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()};
      `;
    } else if (full) {
      return fullLabelStyles();
    } else {
      return labelStyles();
    }
  }}
`;

const StyledLabelText = styled.span`
  ${labelTextStyles()}

  ${({ alt }) => {
    if (alt) {
      return css`
        top: 15px;
        bottom: auto;
      `;
    }
  }}
`;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextarea = styled.textarea`
  ${textAreaStyles()}
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const ContactForm = ({ heading }) => {
  const submitForm = event => {
    event.preventDefault();
    const accountForm = document.getElementById('contact-form');
    const formData = new FormData(accountForm);

    fetch('/.netlify/functions/sendMail', {
      method: 'POST',
      body: JSON.stringify({
        data: JSON.stringify(new URLSearchParams(formData).toString()),
      }),
    })
      .then(() => {
        navigate('/thank-you-for-your-enquiry/');
      })
      .catch(error => console.log(error));
  };
  return (
    <StyledContactForm>
      <StyledContainer>
        <Heading>{heading}</Heading>
        <StyledForm
          id="contact-form"
          name="contact-form"
          method="POST"
          onSubmit={submitForm}
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <StyledLabel honeypot={true}>
            <StyledInput type="text" name="question" />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>First name *</StyledLabelText>
            <StyledInput type="text" name="first-name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Last name *</StyledLabelText>
            <StyledInput type="text" name="last-name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Email address *</StyledLabelText>
            <StyledInput type="email" name="email-address" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Phone number *</StyledLabelText>
            <StyledInput type="tel" name="phone-number" required />
          </StyledLabel>
          <StyledLabel full={true}>
            <StyledLabelText alt={true}>Message *</StyledLabelText>
            <StyledTextarea name="message" required />
          </StyledLabel>
          <StyledSubmit>Send message</StyledSubmit>
        </StyledForm>
      </StyledContainer>
    </StyledContactForm>
  );
};

export default ContactForm;
