import React from 'react';
import Tabs from './Tabs';
import { checkIfMural, translateString } from '../utils';

const ProductInfo = ({
  descriptionHtml,
  sku,
  rollWidth,
  rollHeight,
  patternMatch,
  verticalPatternRepeat,
  fireRatings,
  installMethod,
  paperType,
  eco,
  accordionItems,
  locale
}) => {
  const isMural = checkIfMural(sku);

  const getPatternMatch = patternMatch => {
    const patternMatchInt = parseInt(patternMatch);

    if (patternMatchInt === 1) {
      return 'No Match';
    } else if (patternMatchInt === 2) {
      return 'Straight Match';
    } else if (patternMatchInt === 3) {
      return 'Drop Match';
    }
  };

  const verticalPatternRepeatParsed = verticalPatternRepeat
    ? JSON.parse(verticalPatternRepeat)
    : { value: 0, unit: 'CENTIMETERS' };

  const productSpec = [`SKU: ${sku}`];

  if (!isMural) {
    productSpec.push(
      `Roll Width: ${rollWidth / 10}cm`,
      `Roll Height: ${rollHeight / 1000}m`,
      `Pattern Match: ${patternMatch ? getPatternMatch(patternMatch) : ''}`,
      `Vertical Pattern Repeat: ${verticalPatternRepeatParsed.value}${
        verticalPatternRepeatParsed.unit === 'CENTIMETERS'
          ? 'cm'
          : verticalPatternRepeatParsed.unit
      }`
    );
  }

  productSpec.push(
    `Fire Ratings: ${fireRatings}`,
    `Install Method: ${installMethod}`,
    `Paper Type: ${paperType}`,
    `Environmental Credentials: ${eco}`
  );

  return (
    <Tabs
      items={[
        {
          heading: translateString('productTabs.descriptionHeading', locale),
          content: descriptionHtml,
        },
        {
          heading: translateString('productTabs.specHeading', locale),
          content: `<ul><li>${productSpec.join('</li><li>')}</li></ul>`,
        },
        ...accordionItems,
      ]}
    />
  );
};

export default ProductInfo;
