import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { brandColours, fontSize, fontWeights } from '../styles';
import { Button, HtmlContent } from './ui';
import { getSingleLocaleFields, translateString } from '../utils';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';

const StyledMockupInfo = styled(PopOutSidebar)`
  right: 0;
`;

const StyledText = styled(HtmlContent)`
  p {
    ${fontSize(14)};
  }
`;

const StyledImageWrapper = styled.div`
  margin-top: 15px;
`;

const StyledImageHeading = styled.p`
  font-weight: ${fontWeights.semibold};
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  border-radius: 0;
  background-color: ${brandColours.primary};
`;

const MockupInfo = ({ locale, isMockupInfoOpen, toggleMockupInfoOpen }) => {
  const { allDatoCmsMockupInfo } = useStaticQuery(graphql`
    query MockupInfoQuery {
      allDatoCmsMockupInfo {
        nodes {
          locale
          heading
          text
          beforeImage {
            gatsbyImageData(width: 345)
            alt
          }
          afterImage {
            gatsbyImageData(width: 345)
            alt
          }
          link {
            text
            page {
              ...LinkFragment
            }
          }
        }
      }
    }
  `);

  const { heading, text, beforeImage, afterImage, link } =
    getSingleLocaleFields(allDatoCmsMockupInfo, locale);

  return (
    <StyledMockupInfo
      heading={heading}
      isOpen={isMockupInfoOpen}
      toggleOpen={toggleMockupInfoOpen}
    >
      <StyledContent>
        <StyledText html={text} />
        <StyledImageWrapper>
          <StyledImageHeading>
            {translateString('other.before', locale)}
          </StyledImageHeading>
          <GatsbyImage
            image={beforeImage.gatsbyImageData}
            alt={beforeImage.alt}
          />
        </StyledImageWrapper>
        <StyledImageWrapper>
          <StyledImageHeading>
            {translateString('other.after', locale)}
          </StyledImageHeading>
          <GatsbyImage
            image={afterImage.gatsbyImageData}
            alt={afterImage.alt}
          />
        </StyledImageWrapper>
        <StyledButton to={link.page}>{link.text}</StyledButton>
      </StyledContent>
    </StyledMockupInfo>
  );
};

export default MockupInfo;
