import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';

const StyledNumberedContentList = styled.section`
  padding: 50px 0;
  background-color: ${brandColours.tertiary};

  ${minBreakpointQuery.large`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.ol`
  display: grid;
  gap: 35px;
  counter-reset: number-counter;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledItem = styled.li`
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  counter-increment: number-counter;

  &:before {
    content: counter(number-counter);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 56px;
    width: 56px;
    font-weight: ${fontWeights.bold};
    ${fontSize(26)};
    background-color: ${standardColours.white};
    border-radius: 50%;
  }
`;

const StyledHeading = styled.h3`
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  margin-top: 16px;
  line-height: 1.5;

  ${maxBreakpointQuery.small`
    ${fontSize(14)};
  `}
`;

const NumberedContentList = ({ heading, items }) => (
  <StyledNumberedContentList>
    <StyledHiddenHeading>{heading}</StyledHiddenHeading>
    <Container>
      <StyledItems>
        {items.map(({ id, heading, text }) => (
          <StyledItem key={id}>
            <StyledHeading>{heading}</StyledHeading>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledNumberedContentList>
);

export default NumberedContentList;
