import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  breakpointSizes,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Link, NextArrow, PreviousArrow } from './ui';
import { buildUrl } from '../utils';

const StyledProductCardSlider = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const StyledItems = styled.div`
  position: relative;
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}

  .swiper-button-lock {
    display: none;
  }
`;

const StyledSwiper = styled(Swiper)`
  ${maxBreakpointQuery.medium`
    overflow: visible;
    padding-left: 30px;
    padding-right: 30px;
  `}

  .swiper-slide {
    width: auto;
    max-width: 100%;
  }
`;

const StyledPrevArrow = styled(PreviousArrow)`
  position: absolute;
  left: 6px;
  margin-left: 0;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%);

  ${minBreakpointQuery.smedium`
    left: -20px;
  `}

  ${minBreakpointQuery.xxlarge`
    left: -55px;
  `}
`;

const StyledNextArrow = styled(NextArrow)`
  position: absolute;
  right: 6px;
  margin-left: 0;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%);

  ${minBreakpointQuery.smedium`
    right: -20px;
  `}

  ${minBreakpointQuery.xxlarge`
    right: -55px;
  `}
`;

const StyledItem = styled.article``;

const StyledProductHeading = styled.h3`
  font-weight: ${fontWeights.semibold};
  margin-top: 8px;
`;

const ProductCardSlider = ({ heading, items }) => {
  const prevEl = useRef(null);
  const nextEl = useRef(null);

  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 12,
    breakpoints: {
      [breakpointSizes.medium]: {
        slidesPerView: 3,
      },
      [breakpointSizes.large]: {
        slidesPerView: 4,
      },
    },
    onInit: swiper => {
      swiper.params.navigation.prevEl = [prevEl.current.base];
      swiper.params.navigation.nextEl = [nextEl.current.base];
      swiper.navigation.prevEl = [prevEl.current.base];
      swiper.navigation.nextEl = [nextEl.current.base];
      swiper.navigation.init();
      swiper.navigation.update();
    },
  };

  return (
    <StyledProductCardSlider>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledItems>
          <StyledSwiper {...sliderOptions}>
            {items.map((item, id) => {
              const urlPath = buildUrl(item.slug, item);
              return (
                <SwiperSlide key={id}>
                  <Link to={urlPath}>
                    <StyledItem>
                      <GatsbyImage
                        image={item.cardImageOne.gatsbyImageData}
                        alt={item.cardImageOne.alt}
                      />
                      <StyledProductHeading>
                        <Link to={urlPath}>{item.title}</Link>
                      </StyledProductHeading>
                    </StyledItem>
                  </Link>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
          <StyledPrevArrow ref={prevEl} version={2}>
            <span>Previous</span>
          </StyledPrevArrow>
          <StyledNextArrow alt={true} ref={nextEl} version={2}>
            <span>Next</span>
          </StyledNextArrow>
        </StyledItems>
      </Container>
    </StyledProductCardSlider>
  );
};

export default ProductCardSlider;
