import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  standardColours,
} from '../styles';
import { Button, Svg } from './ui';
import { translateString } from '../utils';
import openIcon from '../images/open-icon.inline.svg';

const StyledWizart = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};
  padding: 6px 22px;
  font-weight: ${fontWeights.regular};
  ${fontSize(12)};

  &:hover {
    svg {
      path {
        fill: ${standardColours.black};
      }
    }
  }
`;

const StyledIcon = styled(Svg)`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 2px;

  path {
    fill: ${standardColours.white};
  }
`;

const Wizart = ({ locale, openVisualizer, ...props }) => (
  <StyledWizart {...props}>
    <StyledButton onClick={openVisualizer}>
      <span>{translateString('product.roomVisualiser', locale)}</span>
      <StyledIcon image={openIcon} />
    </StyledButton>
  </StyledWizart>
);

export default Wizart;
