import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fluidFontSize,
  visuallyHidden,
} from '../styles';
import { Container, Heading } from './ui';
import ReviewCard from './ReviewCard';

const StyledReviews = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 24px;
  `}
`;

const StyledSwiper = styled(Swiper)`
  overflow: visible;

  .swiper-slide {
    height: auto;
  }
`;

const StyledNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
`;

const StyledNavButton = styled.button`
  position: relative;
  height: 36px;
  width: 36px;
  background-color: ${brandColours.tertiary};
  border: none;

  span {
    ${visuallyHidden()};
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 8px;
    width: 8px;
    border: 2px solid ${brandColours.primary};
    border-top: 0;
    border-left: 0;
    border-radius: 1px;
    transform: ${({ isNext }) =>
      isNext
        ? 'translate(calc(-50% - 2px), -50%) rotate(-45deg)'
        : 'translate(calc(-50% + 2px), -50%) rotate(135deg)'};
  }
`;

const Reviews = ({ heading, items }) => {
  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      prevEl: '.slick-prev-reviews',
      nextEl: '.slick-next-reviews',
    },
    breakpoints: {
      [breakpointSizes.small]: {
        slidesPerView: 2,
      },
      [breakpointSizes.medium]: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <StyledReviews>
      <Container narrow={true}>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSwiper {...sliderOptions}>
          {items.map(({ heading, quote, image, name }, id) => (
            <SwiperSlide key={id}>
              <ReviewCard
                heading={heading}
                quote={quote}
                image={image}
                name={name}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <StyledNav>
          <StyledNavButton className="slick-prev-reviews">
            <span>Previous</span>
          </StyledNavButton>
          <StyledNavButton className="slick-next-reviews" isNext={true}>
            <span>Next</span>
          </StyledNavButton>
        </StyledNav>
      </Container>
    </StyledReviews>
  );
};

export default Reviews;
