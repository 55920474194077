import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import tickIcon from '../images/tick-icon.inline.svg';

const StyledFeaturedPoints = styled.section`
  ${sectionMargins()};
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  padding: 10px 25px;
  background-color: ${standardColours.lighterGrey};

  ${minBreakpointQuery.large`
    padding-right: 30px;
    padding-left: 30px;
  `}
`;

const StyledItem = styled.div`
  margin: 30px 0;

  ${maxBreakpointQuery.medium`
    text-align: center;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledHeader = styled.header`
  ${minBreakpointQuery.medium`
    display: flex;
    align-items: center;
  `}
`;

const StyledIcon = styled(Svg)`
  display: inline-block;
  padding: 12px;
  height: 38px;
  width: 38px;
  fill: ${standardColours.black};
  background-color: ${standardColours.white};
  border-radius: 50%;

  ${maxBreakpointQuery.medium`
    margin-bottom: 8px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 16px;
    padding: 18px;
    height: 56px;
    width: 56px;
  `}
`;

const StyledHeading = styled.h3`
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  margin-top: 8px;
  ${fontSize(13)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(15)};
  `}
`;

const FeaturedPoints = ({ heading, items }) => (
  <StyledFeaturedPoints>
    <Container>
      <StyledHiddenHeading>{heading}</StyledHiddenHeading>
      <StyledItems>
        {items.map(({ id, heading, text }) => (
          <StyledItem key={id}>
            <StyledHeader>
              <StyledIcon image={tickIcon} />
              <StyledHeading>{heading}</StyledHeading>
            </StyledHeader>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledFeaturedPoints>
);

export default FeaturedPoints;
