import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
} from '../styles';

const StyledReviewCard = styled.figure`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px;
  height: 100%;
  border: 1px solid ${standardColours.lightGrey};

  ${minBreakpointQuery.tiny`
    padding: 25px;
  `}

  ${minBreakpointQuery.large`
    padding: 30px;
  `}
`;

const StyledHeading = styled.h3`
  margin-bottom: 8px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(16)};
`;

const StyledQuote = styled.blockquote`
  margin: 0;
`;

const StyledText = styled.p`
  font-weight: ${fontWeights.regular};
  ${fontSize(13)};
  line-height: 1.8;
`;

const StyledAuthor = styled.figcaption`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: auto;
  padding-top: 12px;
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;
`;

const ReviewCard = ({ heading, quote, image, name, ...props }) => (
  <StyledReviewCard {...props}>
    <StyledHeading>{heading}</StyledHeading>
    <StyledQuote>
      <StyledText>{quote}</StyledText>
    </StyledQuote>
    <StyledAuthor>
      <StyledImage image={image.gatsbyImageData} alt={image.alt} />
      {name}
    </StyledAuthor>
  </StyledReviewCard>
);

export default ReviewCard;
