import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  fluidFontSize,
} from '../styles';
import { Container, Heading } from './ui';

const StyledImageGallery = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 24px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, 1fr);

  ${minBreakpointQuery.small`
    gap: 15px;
  `}

  ${minBreakpointQuery.large`
    gap: 20px;
  `}
`;

const StyledItem = styled(GatsbyImage)`
  height: 150px;

  ${minBreakpointQuery.tiny`
    height: 190px;
  `}

  ${minBreakpointQuery.small`
    height: 230px;
  `}

  ${minBreakpointQuery.medium`
    height: 270px;
  `}

  ${minBreakpointQuery.large`
    height: 310px;
  `}

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8) {
    grid-column: span 2;
  }

  &:nth-child(4),
  &:nth-child(5) {
    grid-column: span 3;
  }
`;

const ImageGallery = ({ heading, items }) => (
  <StyledImageGallery>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ gatsbyImageData, alt }, i) => (
          <StyledItem key={`image-${i}`} image={gatsbyImageData} alt={alt} />
        ))}
      </StyledItems>
    </Container>
  </StyledImageGallery>
);

export default ImageGallery;
