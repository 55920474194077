import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fontSize,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
} from '../styles';
import { Container, Heading, NextArrow, PreviousArrow } from './ui';
import { getSingleLocaleFields } from '../utils';

const StyledHowMuralsWork = styled.section`
  ${sectionMargins()}
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const StyledItems = styled(Swiper)`
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${standardColours.lightGrey};
  padding: 30px 24px;
`;

const StyledNumber = styled.span`
  text-align: center;
  padding: 10px 17px;
  width: 45px;
  height: 45px;
  color: ${brandColours.primary};
  background-color: ${brandColours.senary};
  border-radius: 50%;
  ${fontSize(20)}
`;

const StyledImage = styled(GatsbyImage)`
  margin-top: 24px;
  box-shadow: 0px 2px 16px 0px ${standardColours.transparentBlack(0.14)};

  ${minBreakpointQuery.medium`
    margin-left: 5px;
    margin-right: 5px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  `}
`;

const StyledText = styled.p`
  margin-top: 24px;
  text-align: center;
  ${fontSize(13)}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    ${fontSize(14)}
  `}
`;

const StyledArrows = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  ${minBreakpointQuery.medium`
    display: none
  `}
`;

const HowMuralsWork = ({ locale }) => {
  const { allDatoCmsHowMuralsWork } = useStaticQuery(graphql`
    query HowMuralsWorkQuery {
      allDatoCmsHowMuralsWork {
        nodes {
          locale
          heading
          items {
            image {
              gatsbyImageData(width: 436, height: 280)
              alt
            }
            text
          }
        }
      }
    }
  `);

  const { heading, items } = getSingleLocaleFields(
    allDatoCmsHowMuralsWork,
    locale
  );

  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      prevEl: '.swiper-prev',
      nextEl: '.swiper-next',
    },
    breakpoints: {
      [breakpointSizes.medium]: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <StyledHowMuralsWork>
      <Container wide={true}>
        <StyledHeading>{heading}</StyledHeading>
        <StyledItems {...sliderOptions}>
          {items.map(({ image, text }, i) => (
            <SwiperSlide key={i}>
              <StyledItem>
                <StyledNumber>{i + 1}</StyledNumber>
                <StyledImage image={image.gatsbyImageData} alt={image.alt} />
                <StyledText>{text}</StyledText>
              </StyledItem>
            </SwiperSlide>
          ))}
        </StyledItems>
        <StyledArrows>
          <PreviousArrow className="swiper-prev" version={2}>
            <span>Previous</span>
          </PreviousArrow>
          <NextArrow alt={true} className="swiper-next" version={2}>
            <span>Next</span>
          </NextArrow>
        </StyledArrows>
      </Container>
    </StyledHowMuralsWork>
  );
};

export default HowMuralsWork;
