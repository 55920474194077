import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  maxBreakpointQuery,
} from '../styles';
import { Button } from './ui';
import { translateString } from '../utils';

const StyledProductOtherCategories = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled.span`
  margin-right: 10px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(14)};

  ${maxBreakpointQuery.mlarge`
   flex-shrink: 0;
   ${fontSize(12)};
  `}
`;

const StyledItems = styled.div`
  ${maxBreakpointQuery.mlarge`
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;
  `}
`;

const StyledButton = styled(Button)`
  margin: 4px 8px 4px 0;
  padding: 4px 12px;
  ${fontSize(14)};
  border: none;
  border-radius: 20px;
  background-color: ${brandColours.primary};

  ${maxBreakpointQuery.mlarge`
    flex-shrink: 0;
    scroll-snap-stop: always;
    scroll-snap-align: start;
    ${fontSize(12)};
  `}

  ${minBreakpointQuery.large`
    margin-right: 12px;
  `}

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${brandColours.secondary};
    background-color: ${brandColours.tertiary};
  }
`;

const ProductOtherCategories = ({ items, locale, ...props }) =>
  items &&
  items.length > 0 && (
    <StyledProductOtherCategories {...props}>
      <StyledText>
        {translateString('product.otherCategoriesHeading', locale)}
      </StyledText>
      <StyledItems>
        {items.map(item => (
          <StyledButton key={item.id} to={item}>
            {item.title}
          </StyledButton>
        ))}
      </StyledItems>
    </StyledProductOtherCategories>
  );

export default ProductOtherCategories;
