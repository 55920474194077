import React, { useState, useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  fluidFontSize,
} from '../styles';
import { Container, Heading } from './ui';

const StyledFeaturedLogos = styled.section`
  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        ${sectionPaddings('20px', '50px')};
        border-top: 1px solid ${standardColours.lighterGrey};
        border-bottom: 1px solid ${standardColours.lighterGrey};
      `;
    } else {
      return css`
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledHeading = styled(Heading)`
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 4px;
  `}
`;

const StyledItems = styled.div`
  padding: 20px 0;
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  padding: 0 15px;
  min-height: 20px;
  min-width: 100px;
  max-height: 40px;
  max-width: 200px;
  scroll-snap-align: center;
`;

const FeaturedLogos = ({ heading, items, isHomePage, ...props }) => {
  const [options, setOptions] = useState({});
  const [sliderRef] = useKeenSlider(options, [
    slider => {
      let timeout;
      let mouseOver = false;

      function clearNextTimeout() {
        clearTimeout(timeout);
      }

      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 2000);
      }

      slider.on('created', () => {
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener('mouseout', () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });

      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
  ]);

  useEffect(() => {
    setOptions({
      loop: true,
      slides: { perView: 1 },
      breakpoints: {
        '(min-width: 380px)': {
          slides: { perView: 2 },
        },
        '(min-width: 620px)': {
          slides: { perView: 3 },
        },
        '(min-width: 870px)': {
          slides: { perView: 4 },
        },
        '(min-width: 1120px)': {
          slides: { perView: 5 },
        },
        '(min-width: 1400px)': {
          slides: { perView: 6 },
        },
      },
    });
  }, []);

  return (
    <StyledFeaturedLogos isHomePage={isHomePage} {...props}>
      <Container>
        {heading && <StyledHeading>{heading}</StyledHeading>}
        <StyledItems ref={sliderRef} className="keen-slider">
          {items.map(({ url, alt }, id) => (
            <StyledItem key={id} className="keen-slider__slide">
              <StyledImage src={url} alt={alt} loading="lazy" />
            </StyledItem>
          ))}
        </StyledItems>
      </Container>
    </StyledFeaturedLogos>
  );
};

export default FeaturedLogos;
