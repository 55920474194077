import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { brandColours, fontSize, fontWeights } from '../styles';
import { Svg } from './ui';
import {
  getDispatchDate,
  getSingleLocaleFields,
  translateString,
} from '../utils';
import vanIcon from '../images/van-icon.inline.svg';
import tickIcon from '../images/tick-alt-icon.inline.svg';

const StyledDispatchNotice = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 16px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ isFreeDelivery }) => {
    if (!isFreeDelivery) {
      return css`
        border-radius: 50%;
        padding: 7px 5px;
        background-color: ${brandColours.septenary};
      `;
    }
  }}
`;

const StyledIcon = styled(Svg)`
  ${({ isFreeDelivery }) => {
    if (isFreeDelivery) {
      return css`
        height: 22px;
        width: 22px;
      `;
    }
  }}
`;

const StyledText = styled.p`
  ${fontSize(12)};
  font-weight: ${fontWeights.semibold};

  span {
    font-weight: ${fontWeights.medium};
  }
`;

const DispatchNotice = ({
  total,
  currency,
  quantity,
  variantPrice,
  locale,
  ...props
}) => {
  const {
    datoCmsProductSitewide: { leadTimeDays, leadTimeExcludedDates },
    allDatoCmsFreeDeliveryThresholdsSitewide,
  } = useStaticQuery(graphql`
    query DispatchNoticeQuery {
      datoCmsProductSitewide {
        leadTimeDays
        leadTimeExcludedDates {
          excludedDate
        }
      }
      allDatoCmsFreeDeliveryThresholdsSitewide {
        nodes {
          locale
          sitewideNotice
          gbp
          usd
          cad
          aud
          nzd
          eur
          hkd
          chf
          aed
          sar
        }
      }
    }
  `);

  const freeDeliveryThresholdsSitewide = getSingleLocaleFields(
    allDatoCmsFreeDeliveryThresholdsSitewide,
    locale
  );

  const isFreeDelivery =
    total || variantPrice
      ? (total ? total : variantPrice.amount * quantity) >
        freeDeliveryThresholdsSitewide[currency.toLowerCase()]
      : false;

  const dispatchDate = getDispatchDate(
    leadTimeExcludedDates,
    leadTimeDays,
    locale
  );

  return (
    <StyledDispatchNotice {...props}>
      <StyledIconWrapper isFreeDelivery={isFreeDelivery}>
        <StyledIcon
          image={isFreeDelivery ? tickIcon : vanIcon}
          isFreeDelivery={isFreeDelivery}
        />
      </StyledIconWrapper>
      <StyledText>
        {isFreeDelivery && (
          <span>
            {translateString('product.freeDelivery', locale).replace(
              'delivery',
              () => (currency === 'USD' ? 'shipping' : 'delivery')
            )}{' '}
            -{' '}
          </span>
        )}
        {`${
          isFreeDelivery
            ? translateString(
                'product.dispatchNoticeFree',
                locale
              ).toLowerCase()
            : translateString('product.dispatchNotice', locale).replace(
                '[dispatchDate]',
                dispatchDate
              )
        } ${locale === 'en' && dispatchDate}`}
      </StyledText>
    </StyledDispatchNotice>
  );
};

export default DispatchNotice;
