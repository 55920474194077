import React, { useRef, useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Container, Button, HtmlContent } from './ui';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { useVariantPrice, formatPrice, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';
import ProductOptionsV2 from './ProductOptionsV2';
import DispatchNotice from './DispatchNotice';
import moveCursor from '../images/cursor-move.svg';

const StyledCroppingTool = styled(PopOutSidebar)`
  right: 0;
  max-width: 100%;
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const StyledWrapper = styled.div`
  padding-bottom: 10px;

  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: 60% calc(40% - 30px);
    column-gap: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 65% calc(35% - 50px);
    column-gap: 50px;
    padding-bottom: 50px;
  `}
`;

const StyledCropArea = styled.div`
  .cropper-container {
    &::after {
      content: '';
      display: ${({ cropHoverActive }) => (cropHoverActive ? 'none' : 'block')};
      background-image: url(${moveCursor});
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      transform: translate(-50%, -50%);
    }
  }

  .cropper-view-box {
    outline: none;
    border: 1px dashed ${standardColours.white};
  }

  .cropper-move {
    cursor: url(${moveCursor}), move;
  }

  .cropper-face {
    background: none;
  }

  .cropper-modal {
    opacity: 0.4;
  }
`;

const StyledHeading = styled.h3`
  ${fontSize(26)}
  font-weight: ${fontWeights.bold};
  color: ${brandColours.primary};
  line-height: 1.2;
  margin-bottom: 16px;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
    ${fontSize(28)}
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 24px;
    ${fontSize(30)}
  `}
`;

const StyledCanvasWrapper = styled.div``;

const StyledCanvas = styled.canvas`
  display: block;
  max-width: 100%;
`;

const StyledContentArea = styled(HtmlContent)`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid ${standardColours.grey};

  ${minBreakpointQuery.medium`
    text-align: center;
  `}

  p {
    ${fontSize(12)}
    margin-bottom: 8px;

    ${minBreakpointQuery.medium`
      ${fontSize(14)}
    `}

    strong {
      ${fontSize(14)}

      ${minBreakpointQuery.medium`
        ${fontSize(16)}
      `}
    }
  }

  ul {
    margin-bottom: 8px;

    ${minBreakpointQuery.medium`
      display: flex;
      justify-content: center;
    `}

    li {
      ${fontSize(12)}

      ${minBreakpointQuery.medium`
        ${fontSize(14)}
      `}

      &::before {
        height: 1px;
      }
    }
  }
`;

const StyledInner = styled.form`
  ${minBreakpointQuery.mlarge`
    grid-column: 2;
    grid-row: 1 / 3;
  `}
`;

const StyledValidation = styled.div`
  margin: 20px 0;
  border: 1px solid ${standardColours.darkGrey};
  padding: 16px;
  border-radius: 5px;
  background-color: ${({ validationMessage }) =>
    validationMessage === 'allow' ? '#fedfc9' : '#ff6961'};
`;

const StyledValidationText = styled.p`
  ${fontSize(12)}
`;

const StyledTermsConditions = styled.label`
  margin: 20px 0;
  display: block;
`;

// moved up for checked
const StyledCheckboxText = styled.span`
  position: relative;
  display: block;
  padding-left: 35px;
  ${fontSize(14)};
  color: ${standardColours.black};
  font-weight: ${fontWeights.regular};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    border: 1px solid ${standardColours.black};
  }

  &:before {
    left: 0;
    height: 25px;
    width: 25px;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &:after {
    left: 6px;
    height: 6px;
    width: 12px;
    border-top: 0;
    border-right: 0;
    opacity: 0;
    transform: translateY(calc(-50% - 2px)) rotate(-45deg);
    transition: ${standardTransition('opacity')};
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledCheckboxText} {
    &:after {
      opacity: 1;
    }
  }
`;

const StyledTotal = styled.p`
  font-weight: ${fontWeights.bold};
  ${fontSize(20)}
  margin: 20px 0;
`;

const StyledDispatchNotice = styled(DispatchNotice)`
  margin-bottom: 16px;
`;

const StyledAddButton = styled(Button)`
  width: 100%;
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};
`;

const CroppingTool = ({
  image,
  croppingMode,
  toggleCroppingModeOpen,
  options,
  handleChange,
  variants,
  getVariant,
  selectedVariant,
  selectedUnit,
  cropWidth,
  cropHeight,
  inputFocus,
  minOrderValues,
  paperTypes,
  total,
  setTotal,
  setCropWidth,
  setCropHeight,
  setSelectedUnit,
  datoCmsCroppingTool: { contentBlock1, contentBlock2 },
  maxCropImages,
  enableWideCrop,
  locale,
  excessMessaging,
}) => {
  const { addProductsToCheckout, currency, currencyLoaded } =
    useContext(StoreContext);

  const variantPrice = useVariantPrice(selectedVariant);

  const [isCustomColour, setIsCustomColour] = useState(false);
  const [cropHoverActive, setCropHoverActive] = useState(false);

  const cropperRef = useRef();
  const [cropperInstance, setCropperInstance] = useState();
  const [numberOfCropImages, setNumberOfCropImages] = useState(1);
  const [currentCropperImage, setCurrentCropperImage] = useState(image);

  const [validationMessage, setValidationMessage] = useState();
  const [readValidation, setReadValidation] = useState(false);

  const timeout = useRef(null);

  // Calculate min order value
  const minOrderQuanity = Math.round(
    minOrderValues[variantPrice.currencyCode] / variantPrice.amount
  );

  const minOrderValue = variantPrice.amount * minOrderQuanity;

  const calculatePrice = (width, height) => {
    // Calculate m2 quanity
    const m2 =
      ((width * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((height * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    // if validation is active remove if passing
    if (validationMessage && m2 >= 1) {
      setValidationMessage();
    }

    // Min order value check
    setTotal(
      variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue
        ? minOrderValue
        : variantPrice.amount * (m2.toFixed(1) * 10)
    );
  };

  const convertToMM = value => {
    if (selectedUnit === 'cm') {
      return value * 10;
    } else {
      return value * 25.4;
    }
  };

  const unitConversion = selectedUnit
    ? selectedUnit === 'cm'
      ? 10
      : 1
    : undefined;

  const handleSubmit = async e => {
    e.preventDefault();

    const imageElement = cropperRef.current;
    const cropData = imageElement.cropBoxData;

    const cropLeft = cropData.left;
    const cropTop = cropData.top;
    const cropAreaWidth = cropData.width;
    const cropAreaHeight = cropData.height;

    const m2 =
      ((cropWidth * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((cropHeight * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    if (
      variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue &&
      !readValidation
    ) {
      setValidationMessage({
        message: `These wall dimensions are smaller than our minimum charge. You can still order, but you will be charged ${formatPrice(
          {
            amount: total,
            currencyCode: variantPrice.currencyCode,
          }
        )}`,
        type: 'allow',
      });

      setReadValidation(true);
      setTimeout(() => {
        document
          .getElementById('wall-dimensions')
          .scrollIntoView({ behavior: 'smooth' });
      }, 100);
      return;
    } else {
      setValidationMessage();
    }

    // Convert wall size to mm

    const wallWidthMM = convertToMM(cropWidth);
    const wallHeightMM = convertToMM(cropHeight);

    // px to mm conversion rate

    const conversionRate =
      cropLeft === 0
        ? cropAreaHeight / wallHeightMM
        : cropAreaWidth / wallWidthMM;

    const offset = {
      type: cropLeft === 0 ? 'top' : 'left',
      amount:
        cropLeft === 0 ? cropTop / conversionRate : cropLeft / conversionRate,
      widthMM: wallWidthMM,
      heightMM: wallHeightMM,
      images: numberOfCropImages,
    };

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity:
          m2.toFixed(1) * 10 < minOrderQuanity
            ? minOrderQuanity
            : m2.toFixed(1) * 10,
        customAttributes: [
          { key: 'Width', value: cropWidth },
          { key: 'Height', value: cropHeight },
          { key: 'Unit', value: selectedUnit },
          {
            key: 'Crop data',
            value: JSON.stringify(offset),
          },
        ],
      },
    ];

    await addProductsToCheckout(lineItems);

    toggleCroppingModeOpen();
    setReadValidation(false);
  };

  const rebuildCropper = (cropImage, cropImages) => {
    setNumberOfCropImages(cropImages);
    cropperInstance && cropperInstance.destroy();
    cropImage.src = image.url;
    cropImage.crossOrigin = 'anonymous';
  };

  const cropperOptions = {
    guides: false,
    dragMode: 'move',
    viewMode: 3,
    rotatable: false,
    scalable: false,
    movable: false,
    zoomable: false,
    zoomOnTouch: false,
    zoomOnWheel: false,
    cropBoxResizable: false,
    center: false,
    initialAspectRatio: 100 / 100,
    aspectRatio: cropWidth / cropHeight,
    autoCropArea: 1,
    restore: false,
    minContainerHeight: 0,
    ready() {
      setCropperInstance(this.cropper);
    },
  };

  useEffect(() => {
    const cropImage = new Image();
    let cropImages = numberOfCropImages;

    cropImage.addEventListener('load', () => {
      let cropper;
      const canvas = document.getElementById('canvas');

      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

      const width = cropImage.naturalWidth;
      const height = cropImage.naturalHeight;

      canvas.width = width * cropImages;
      canvas.height = height;

      // Add each additional image
      for (let index = 0; index < cropImages; index++) {
        canvas
          .getContext('2d')
          .drawImage(
            cropImage,
            width * (index === 0 ? 0 : index),
            0,
            width,
            height
          );
      }

      cropper = new Cropper(canvas, cropperOptions);

      cropperRef.current = cropper;
    });

    window.clarity && window.clarity('stop');

    clearTimeout(timeout.current);

    // initial load
    if (!cropWidth && !cropHeight && image.url !== currentCropperImage.url) {
      rebuildCropper(cropImage, cropImages);
    } else if (cropWidth > 10 && cropHeight > 10) {
      // We need to do more
      if (!enableWideCrop) {
        if (currentCropperImage.url !== image.url) {
          rebuildCropper(cropImage, cropImages);
        } else {
          cropperInstance &&
            cropperInstance.setAspectRatio(cropWidth / cropHeight);
        }
      } else {
        timeout.current = setTimeout(() => {
          const ratioCalc = image.width / image.height;

          // -0.01 used to kick the images up just before it needs to be done by around 1cm

          cropImages =
            Math.floor(cropWidth / cropHeight / (ratioCalc - 0.01)) + 1 <=
            (maxCropImages ? maxCropImages : 3)
              ? Math.floor(cropWidth / cropHeight / (ratioCalc - 0.01)) + 1
              : maxCropImages
              ? maxCropImages
              : 3;

          // If the number is not odd then add one
          if (!(cropImages % 2)) {
            cropImages = cropImages + 1;
          }

          // See if we need to rebuild the cropper
          if (
            cropImages === numberOfCropImages &&
            currentCropperImage.url === image.url
          ) {
            cropperInstance &&
              cropperInstance.setAspectRatio(cropWidth / cropHeight);
          } else {
            // We do need to rebuild
            rebuildCropper(cropImage, cropImages);
          }
        }, 800);
      }
    }

    setCurrentCropperImage(image);
  }, [cropWidth, cropHeight, image, enableWideCrop]);

  useEffect(() => {
    calculatePrice(cropWidth, cropHeight);
  }, [selectedUnit, variantPrice, cropWidth, cropHeight]);

  useEffect(() => {
    setSelectedUnit(selectedUnit);
  }, [selectedUnit]);

  return (
    <StyledCroppingTool
      incBackButton={true}
      locale={locale}
      isOpen={croppingMode}
      toggleOpen={toggleCroppingModeOpen}
    >
      <StyledContent>
        <StyledContainer>
          <StyledWrapper>
            <StyledCropArea
              cropHoverActive={cropHoverActive}
              onMouseEnter={() => setCropHoverActive(true)}
              onMouseLeave={() => setCropHoverActive(false)}
            >
              <StyledHeading>
                {translateString('croppingTool.heading', locale)}
              </StyledHeading>
              <StyledCanvasWrapper>
                <StyledCanvas id="canvas"></StyledCanvas>
              </StyledCanvasWrapper>
            </StyledCropArea>
            <StyledInner onSubmit={e => handleSubmit(e)}>
              {validationMessage && validationMessage.message && (
                <StyledValidation
                  validationMessage={
                    validationMessage && validationMessage.type
                  }
                >
                  <StyledValidationText>
                    {validationMessage.message}
                  </StyledValidationText>
                </StyledValidation>
              )}
              <ProductOptionsV2
                options={options}
                getVariant={getVariant}
                variants={variants}
                variantPrice={variantPrice}
                selectedVariant={selectedVariant}
                handleChange={handleChange}
                isMural={true}
                isCustomColour={isCustomColour}
                selectedUnit={selectedUnit}
                minOrderValues={minOrderValues}
                paperTypes={paperTypes}
                cropWidth={cropWidth}
                cropHeight={cropHeight}
                inputFocus={inputFocus}
                croppingMode={croppingMode}
                setTotal={setTotal}
                setCropWidth={setCropWidth}
                setCropHeight={setCropHeight}
                setSelectedUnit={setSelectedUnit}
                setIsCustomColour={setIsCustomColour}
                excessMessaging={excessMessaging}
                isCroppingTool={true}
                locale={locale}
              />
              <StyledTermsConditions>
                <StyledCheckbox
                  id="terms-conditions-optin"
                  name="terms-conditions-optin"
                  type="checkbox"
                  required={true}
                />
                <StyledCheckboxText>
                  {translateString('croppingTool.terms', locale)}
                </StyledCheckboxText>
              </StyledTermsConditions>
              <StyledTotal>
                {translateString('croppingTool.totalPrice', locale)}{' '}
                {variantPrice &&
                  currencyLoaded &&
                  unitConversion &&
                  formatPrice({
                    amount: total
                      ? total
                      : variantPrice.amount * unitConversion,
                    currencyCode: variantPrice.currencyCode,
                    locale: locale,
                  })}
              </StyledTotal>
              <StyledDispatchNotice
                total={total}
                currency={currency}
                locale={locale}
              />
              {isCustomColour ? (
                <StyledAddButton
                  onClick={e => {
                    e.preventDefault();
                    navigate('/contact/');
                  }}
                >
                  {translateString('product.contact', locale)}
                </StyledAddButton>
              ) : (
                <StyledAddButton>
                  {translateString('product.addToCart', locale)}
                </StyledAddButton>
              )}
            </StyledInner>
            <div>
              {contentBlock1 && <StyledContentArea html={contentBlock1} />}
              {contentBlock2 && <StyledContentArea html={contentBlock2} />}
            </div>
          </StyledWrapper>
        </StyledContainer>
      </StyledContent>
    </StyledCroppingTool>
  );
};

export default CroppingTool;
