import React from 'react';
import { graphql } from 'gatsby';
import Accordion from './Accordion';
import BackgroundImageCta from './BackgroundImageCta';
import ContactForm from './ContactForm';
import ContainedImage from './ContainedImage';
import Content from './Content';
import CreateTradeAccountForm from './CreateTradeAccountForm';
import ExternalVideo from './ExternalVideo';
import FeaturedCustomerPhotos from './FeaturedCustomerPhotos';
import FeaturedLogos from './FeaturedLogos';
import FeaturedPoints from './FeaturedPoints';
import FullWidthImage from './FullWidthImage';
import IconAccordion from './IconAccordion';
import IconContentBlocks from './IconContentBlocks';
import ImageContent from './ImageContent';
import ImageGallery from './ImageGallery';
import InternalVideo from './InternalVideo';
import NumberedContentList from './NumberedContentList';
import ProjectListing from './ProjectListing';
import Reviews from './Reviews';
import Tabs from './Tabs';
import Testimonials from './Testimonials';
import TradeSignUp from './TradeSignUp';

const ModularBlocks = ({ items, locale }) =>
  items.map(item => (
    <React.Fragment key={item.id}>
      {item.model.apiKey === 'accordion_modular_block' && (
        <Accordion
          heading={item.instance.heading}
          items={item.instance.accordionItems}
        />
      )}
      {item.model.apiKey === 'background_image_cta_modular_block' && (
        <BackgroundImageCta
          backgroundImage={item.backgroundImage}
          heading={item.heading}
          text={item.text}
          link={item.link}
        />
      )}
      {item.model.apiKey === 'contact_form_modular_block' && (
        <ContactForm heading={item.heading} />
      )}
      {item.model.apiKey === 'contained_image_modular_block' && (
        <ContainedImage image={item.image} />
      )}
      {item.model.apiKey === 'content_modular_block' && (
        <Content
          html={item.content}
          buttonLink={item.buttonLink}
          contain={item.contain}
          twoColumns={item.twoColumns}
          highlight={item.highlight}
        />
      )}
      {item.model.apiKey === 'create_trade_account_form_modular_block' && (
        <CreateTradeAccountForm heading={item.heading} text={item.text} />
      )}
      {item.model.apiKey === 'external_video_modular_block' && (
        <ExternalVideo video={item.externalVideo} />
      )}
      {item.model.apiKey === 'featured_customer_photos_modular_block' && (
        <FeaturedCustomerPhotos
          heading={item.heading}
          items={item.customerPhotos}
        />
      )}
      {item.model.apiKey === 'featured_logos_modular_block' && (
        <FeaturedLogos heading={item.heading} items={item.logos} />
      )}
      {item.model.apiKey === 'featured_points_modular_block' && (
        <FeaturedPoints
          heading={item.instance.heading}
          items={item.instance.featuredPoints}
        />
      )}
      {item.model.apiKey === 'full_width_image_modular_block' && (
        <FullWidthImage image={item.image} />
      )}
      {item.model.apiKey === 'icon_accordion_modular_block' && (
        <IconAccordion
          heading={item.instance.heading}
          items={item.instance.iconAccordionItems}
        />
      )}
      {item.model.apiKey === 'icon_content_blocks_modular_block' && (
        <IconContentBlocks
          heading={item.instance.heading}
          items={item.instance.iconContentBlocks}
        />
      )}
      {item.model.apiKey === 'image_content_modular_block_v1' && (
        <ImageContent
          image={item.image}
          heading={item.heading}
          text={item.text}
          flip={item.flip}
          version={1}
        />
      )}
      {item.model.apiKey === 'image_content_modular_block_v2' && (
        <ImageContent
          image={item.image}
          heading={item.heading}
          text={item.text}
          flip={item.flip}
          version={2}
        />
      )}
      {item.model.apiKey === 'image_gallery_modular_block' && (
        <ImageGallery heading={item.heading} items={item.images} />
      )}
      {item.model.apiKey === 'internal_video_modular_block' && (
        <InternalVideo video={item.internalVideo} />
      )}
      {item.model.apiKey === 'numbered_content_list_modular_block' && (
        <NumberedContentList
          heading={item.instance.heading}
          items={item.instance.numberedContentListItems}
        />
      )}
      {item.model.apiKey === 'project_listing_modular_block' && (
        <ProjectListing heading={item.heading} locale={locale} />
      )}
      {item.model.apiKey === 'reviews_modular_block' && (
        <Reviews heading={item.heading} items={item.reviews} />
      )}
      {item.model.apiKey === 'tabs_modular_block' && (
        <Tabs heading={item.instance.heading} items={item.instance.tabs} />
      )}
      {item.model.apiKey === 'testimonials_modular_block' && (
        <Testimonials items={item.testimonials} locale={locale} />
      )}
      {item.model.apiKey === 'trade_sign_up_modular_block' && (
        <TradeSignUp
          stepsHeading={item.stepsHeading}
          steps={item.steps}
          formHeading={item.formHeading}
          formText={item.formText}
        />
      )}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      accordionItems {
        id
        heading
        content
      }
    }
  }

  fragment BackgroundImageCtaModularBlockFragment on DatoCmsBackgroundImageCtaModularBlock {
    id
    model {
      apiKey
    }
    backgroundImage {
      gatsbyImageData(width: 1440, height: 650, layout: FULL_WIDTH)
      alt
    }
    heading
    text
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ContactFormModularBlockFragment on DatoCmsContactFormModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1220)
      alt
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    buttonLink {
      text
      page {
        ...LinkFragment
      }
    }
    twoColumns
    highlight
    contain
  }

  fragment CreateTradeAccountFormModularBlockFragment on DatoCmsCreateTradeAccountFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedCustomerPhotosModularBlockFragment on DatoCmsFeaturedCustomerPhotosModularBlock {
    id
    model {
      apiKey
    }
    heading
    customerPhotos {
      image {
        gatsbyImageData(width: 360, height: 400)
        alt
      }
      heading
      text
      product {
        ...LinkFragment
      }
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      url
      alt
    }
  }

  fragment FeaturedPointsModularBlockFragment on DatoCmsFeaturedPointsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      featuredPoints {
        id
        heading
        text
      }
    }
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 2000, layout: FULL_WIDTH)
      alt
    }
  }

  fragment IconAccordionModularBlockFragment on DatoCmsIconAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      iconAccordionItems {
        id
        icon {
          url
          alt
        }
        heading
        content
      }
    }
  }

  fragment IconContentBlocksModularBlockFragment on DatoCmsIconContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      iconContentBlocks {
        id
        icon {
          url
          alt
        }
        heading
        text
      }
    }
  }

  fragment ImageContentModularBlockV1Fragment on DatoCmsImageContentModularBlockV1 {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 700, height: 480)
      alt
    }
    heading
    text
    flip
  }

  fragment ImageContentModularBlockV2Fragment on DatoCmsImageContentModularBlockV2 {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1000, height: 540)
      alt
    }
    heading
    text
    flip
  }

  fragment ImageGalleryModularBlockFragment on DatoCmsImageGalleryModularBlock {
    id
    model {
      apiKey
    }
    heading
    images {
      gatsbyImageData(width: 605, height: 310)
      alt
    }
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment NumberedContentListModularBlockFragment on DatoCmsNumberedContentListModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      numberedContentListItems {
        id
        heading
        text
      }
    }
  }

  fragment ProjectListingModularBlockFragment on DatoCmsProjectListingModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment ReviewsModularBlockFragment on DatoCmsReviewsModularBlock {
    id
    model {
      apiKey
    }
    heading
    reviews {
      heading
      quote
      image {
        gatsbyImageData(width: 46, height: 46)
        alt
      }
      name
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TestimonialsModularBlockFragment on DatoCmsTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    testimonials {
      id
      heading
      text
    }
  }

  fragment TradeSignUpModularBlockFragment on DatoCmsTradeSignUpModularBlock {
    id
    model {
      apiKey
    }
    stepsHeading
    steps {
      heading
      text
    }
    formHeading
    formText
  }
`;
