import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledIconAccordion = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 24px;
  `}
`;

const StyledItem = styled.article`
  margin-top: 10px;
  padding: 20px 16px;
  border: 1px solid ${standardColours.lightGrey};

  ${minBreakpointQuery.small`
    margin-top: 16px;
    padding-right: 20px;
    padding-left: 20px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  padding: 0 25px 0 55px;

  ${minBreakpointQuery.small`
    padding-right: 30px;
    padding-left: 60px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 35px;
    padding-left: 65px;
  `}
`;

const StyledIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  height: 40px;
  width: 40px;
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding: 13px;
    height: 43px;
    width: 43px;
  `}

  ${minBreakpointQuery.large`
    padding: 14px;
    height: 46px;
    width: 46px;
  `}
`;

const StyledSubHeading = styled.h3`
  padding: 10px 0;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};
  line-height: 1.4;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-top: 11px;
    padding-bottom: 11px;
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    padding-top: 12px;
    padding-bottom: 12px;
    ${fontSize(16)};
  `}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 2px;
    height: 8px;
    width: 8px;
    border: 2px solid ${brandColours.primary};
    border-top: 0;
    border-left: 0;
    border-radius: 1px;
    transform: ${({ display }) =>
      display
        ? 'translateY(calc(-50% + 3px)) rotate(-135deg)'
        : 'translateY(calc(-50% - 3px)) rotate(45deg)'};

    ${minBreakpointQuery.small`
      height: 10px;
      width: 10px;
    `}
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};

  p {
    font-weight: ${fontWeights.regular};
    ${fontSize(12)};

    ${minBreakpointQuery.small`
      ${fontSize(13)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(14)};
    `}
  }
`;

const IconAccordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledIconAccordion>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ icon, heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem key={i}>
              <StyledWrapper>
                <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
                <StyledSubHeading
                  display={display}
                  onClick={() => setActiveItem(display ? undefined : i)}
                >
                  {heading}
                </StyledSubHeading>
              </StyledWrapper>
              <StyledWrapper>
                <StyledContent html={content} display={display} />
              </StyledWrapper>
            </StyledItem>
          );
        })}
      </Container>
    </StyledIconAccordion>
  );
};

export default IconAccordion;
