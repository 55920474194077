import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';

const StyledPaperTypesInfo = styled(PopOutSidebar)`
  right: 0;
`;

const StyledImage = styled(GatsbyImage)`
  ${({ active }) => {
    if (!active) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledTabs = styled.div`
  display: flex;
  margin: 10px 0 20px;
  background: ${standardColours.lighterGrey};
`;

const StyledTab = styled.button`
  position: relative;
  padding: 12px 10px;
  width: 100%;
  color: ${standardColours.darkerGrey};
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
  background: none;
  border: none;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    height: 16px;
    width: 16px;
    background-color: ${brandColours.primary};
    opacity: 0;
    transform: translate(-50%, 50%) rotate(45deg);
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${standardColours.white};
        background-color: ${brandColours.primary};

        &:after {
          opacity: 1;
        }
      `;
    }
  }}
`;

const StyledText = styled.p`
  font-weight: ${fontWeights.regular};
  ${fontSize(15)};
  line-height: 1.7;

  ${({ active }) => {
    if (!active) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const PaperTypesInfo = ({
  paperTypes,
  isPaperTypesInfoOpen,
  togglePaperTypesInfoOpen,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <StyledPaperTypesInfo
      heading="Our Paper Types"
      isOpen={isPaperTypesInfoOpen}
      toggleOpen={togglePaperTypesInfoOpen}
    >
      <StyledContent>
        {paperTypes.map(({ image }, i) => (
          <StyledImage
            key={i}
            image={image.gatsbyImageData}
            alt={image.alt}
            active={i === activeTab}
          />
        ))}
        <StyledTabs>
          {paperTypes.map(({ name }, i) => (
            <StyledTab
              key={i}
              type="button"
              active={i === activeTab}
              onClick={() => {
                setActiveTab(i);
              }}
            >
              {name}
            </StyledTab>
          ))}
        </StyledTabs>
        {paperTypes.map(({ longText }, i) => (
          <StyledText key={i} active={i === activeTab}>
            {longText}
          </StyledText>
        ))}
      </StyledContent>
    </StyledPaperTypesInfo>
  );
};

export default PaperTypesInfo;
