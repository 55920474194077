import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  fluidFontSize,
} from '../styles';
import { Container, Heading } from './ui';
import ProjectCard from './ProjectCard';
import { getCollectionLocaleFields } from '../utils';

const StyledProjectListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  ${fluidFontSize(
    '16px',
    '22px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 24px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px 20px;

  ${minBreakpointQuery.tiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const ProjectListing = ({ heading, locale }) => {
  const { allDatoCmsProject } = useStaticQuery(graphql`
    query ProjectListingQuery {
      allDatoCmsProject {
        group(field: locale) {
          fieldValue
          nodes {
            title
            featuredImage {
              gatsbyImageData(width: 370, height: 370)
              alt
            }
            ...LinkFragment
          }
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsProject, locale);

  return (
    nodes.length > 0 && (
      <StyledProjectListing>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledItems>
            {nodes.map((node, id) => (
              <ProjectCard
                key={id}
                heading={node.title}
                link={node}
                image={node.featuredImage}
              />
            ))}
          </StyledItems>
        </Container>
      </StyledProjectListing>
    )
  );
};

export default ProjectListing;
