import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from './ui';
import ProductQuantity from './ProductQuantity';
import { StoreContext } from '../context/StoreContext';
import { ProductContext } from './Product';
import { brandColours } from '../styles';
import { translateString } from '../utils';

const StyledProductForm = styled.form`
  display: grid;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
`;

const StyledAddToCart = styled.div`
  display: flex;
`;

const StyledAddButton = styled(Button)`
  margin-left: ${({ isMural }) => (isMural ? '0' : '15')}px;
  width: 100%;
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  ${({ isMural }) => {
    if (isMural) {
      return css`
        margin: 20px 0;
      `;
    }
  }}
`;

const ProductForm = ({ toggleCroppingModeOpen, locale, isMural }) => {
  const { addProductsToCheckout, currency } = useContext(StoreContext);
  const { selectedVariant } = useContext(ProductContext);

  const [quantity, setQuantity] = useState(1);

  const changeQuantity = e => {
    const value = e.target.value;

    if (value === 'remove') {
      setQuantity(quantity > 1 ? quantity - 1 : 1);
    } else if (value === 'add') {
      setQuantity(quantity ? quantity + 1 : 1);
    } else {
      setQuantity(parseInt(e.target.value));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: quantity || 1,
      },
    ];

    await addProductsToCheckout(lineItems);

    setQuantity(1);
  };

  return isMural ? (
    <StyledAddButton isMural={isMural} onClick={() => toggleCroppingModeOpen()}>
      {locale === 'en'
        ? currency === 'USD'
          ? 'Customize & Buy'
          : 'Customise & Buy'
        : translateString('product.customiseBuy', locale)}
    </StyledAddButton>
  ) : (
    <StyledProductForm onSubmit={e => handleSubmit(e)}>
      <StyledAddToCart>
        <ProductQuantity quantity={quantity} changeFunction={changeQuantity} />
        <StyledAddButton>
          {translateString('product.addToCart', locale)}
        </StyledAddButton>
      </StyledAddToCart>
    </StyledProductForm>
  );
};

export default ProductForm;
