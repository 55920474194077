import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  selectWrapStyles,
  selectStyles,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Svg } from './ui';
import PaperTypesInfo from './PaperTypesInfo';
import {
  useVariantPrice as getVariantPrice,
  formatPrice,
  getColourHex,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';
import starIcon from '../images/star.inline.svg';

const StyledProductOptions = styled.div`
  ${({ isPaperSamples }) => {
    if (!isPaperSamples) {
      return css`
        margin-top: 20px;

        ${minBreakpointQuery.small`
          margin-top: 24px;
        `}

        ${minBreakpointQuery.large`
          margin-top: 28px;
        `}
      `;
    }
  }}
`;

const StyledOption = styled.div`
  margin-top: 18px;

  ${minBreakpointQuery.small`
    margin-top: 22px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledHeadingButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledHeading = styled.span`
  display: block;
  ${fontSize(14)};
`;

const StyledButton = styled.button`
  color: ${standardColours.darkerGrey};
  font-weight: ${fontWeights.regular};
  ${fontSize(14)};
  text-decoration: underline;
  background: none;
  border: none;
`;

const StyledPaperTypeOption = styled.div`
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

// moved up for checked styling
const StyledText = styled.label`
  position: relative;
  display: block;
  padding: 14px 50px 14px 16px;
  line-height: 1.4;
  border: 1px solid ${standardColours.grey};
  border-radius: 5px;
  cursor: pointer;
  transition: ${standardTransition('border-color')};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    right: 16px;
  }

  &:before {
    height: 24px;
    width: 24px;
    border: 1px solid ${standardColours.grey};
    border-radius: 50%;
    transition: ${standardTransition('border-color')};
  }

  &:after {
    margin: 7px 10px;
    height: 9px;
    width: 4px;
    border: 1px solid ${standardColours.black};
    border-top: 0;
    border-left: 0;
    border-radius: 1px;
    opacity: 0;
    transform: rotate(45deg);
    transition: ${standardTransition('opacity')};
  }

  svg {
    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledPopular = styled.span`
  display: inline-block;
`;

const StyledIcon = styled(Svg)`
  width: 18px;
  height: 18px;
  margin: 0 5px;
  vertical-align: sub;
`;

const StyledPaperTypeDescription = styled.span`
  display: block;
  margin-top: 2px;
  color: ${standardColours.darkerGrey};
  font-weight: ${fontWeights.regular};
  ${fontSize(14)};
`;

const StyledInput = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledText} {
    border-color: ${standardColours.black};

    &:before {
      border-color: ${standardColours.black};
    }

    &:after {
      opacity: 1;
    }
  }
`;

// moved up for colour padding
const StyledSelect = styled.select`
  ${selectStyles()};
  height: 55px;
  border-radius: 5px;
  outline-color: ${standardColours.black};
`;

const StyledSelectWrap = styled.span`
  ${selectWrapStyles()};

  ${({ variantColour }) => {
    if (variantColour) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 16px;
          height: 25px;
          width: 25px;
          background-color: ${({ variantColour }) =>
            variantColour
              ? variantColour
              : standardColours.transparentBlack(0.5)};
          border-radius: 50%;
          transform: translateY(-50%);
        }

        ${StyledSelect} {
          padding-left: 50px;
        }
      `;
    }
  }}

  &:after {
    border-width: 1px;
  }
`;

const PaperTypeOption = ({
  name,
  value,
  variants,
  translation,
  getVariant,
  selectedVariant,
  handleChange,
  paperTypes,
  isMural,
  isPaperSamples,
  unit = 'cm',
  locale,
  i,
}) => {
  const getIdentifier = value => `${isPaperSamples ? 'PS' : 'WP'}-${value}`;

  const variantOptions = {
    ...selectedVariant.selectedOptions,
    [i]: {
      name,
      value,
    },
  };

  const variant = getVariant(variants, variantOptions);

  if (!variant) {
    return;
  }

  const variantPrice = getVariantPrice(variant);

  const unitConversion = unit === 'cm' ? 10 : 0.92936802974;

  const displayText =
    unit === 'cm'
      ? translateString('product.perM', locale)
      : translateString('product.perFt', locale);

  return (
    <StyledPaperTypeOption>
      <StyledInput
        type="radio"
        id={getIdentifier(value)}
        name={getIdentifier(name)}
        value={value}
        checked={
          getIdentifier(value) ===
          getIdentifier(selectedVariant.selectedOptions[i].value)
        }
        onChange={() => handleChange(name, value, i)}
      />
      <StyledText for={getIdentifier(value)}>
        {translation
          ? translation
          : value === 'Self Adhesive'
          ? 'Peel & Stick'
          : value}
        {!isPaperSamples && (
          <>
            {' - '}
            {formatPrice({
              amount: variantPrice.amount * (isMural ? unitConversion : 1),
              currencyCode: variantPrice.currencyCode,
            })}{' '}
            {isMural ? displayText : translateString('product.perRoll', locale)}
            {isMural && <sup>2</sup>}{' '}
            {value === 'Luxury' && (
              <StyledPopular>
                {' - '}
                <StyledIcon image={starIcon} />
                {translateString('product.popular', locale)}
                <StyledIcon image={starIcon} />
              </StyledPopular>
            )}
            <StyledPaperTypeDescription>
              {
                paperTypes.find(
                  ({ name }) => name === value || name === translation
                )?.shortText
              }
            </StyledPaperTypeDescription>
          </>
        )}
      </StyledText>
    </StyledPaperTypeOption>
  );
};

const ProductOptions = ({
  options,
  handleChange,
  variants,
  getVariant,
  selectedVariant,
  paperTypes,
  isMural,
  isPaperSamples,
  unit,
  locale,
}) => {
  const { setOverlayActive, currency } = useContext(StoreContext);

  const [isPaperTypesInfoOpen, setPaperTypesInfoOpen] = useState();

  const togglePaperTypesInfoOpen = () => {
    setOverlayActive(!isPaperTypesInfoOpen);
    setPaperTypesInfoOpen(!isPaperTypesInfoOpen);
  };

  return (
    <StyledProductOptions isPaperSamples={isPaperSamples}>
      {options
        .filter(option => option.name !== 'Product Type')
        .map(
          (option, i) =>
            option.values.length > 1 && (
              <StyledOption key={i}>
                <StyledHeadingButton>
                  <StyledHeading>
                    {translateString('product.select', locale)}{' '}
                    {option.name === 'Color'
                      ? option.translations && option.translations.name
                        ? option.translations.name.toLowerCase()
                        : currency !== 'USD'
                        ? 'Colour'
                        : 'Color'
                      : option.translations && option.translations.name
                      ? option.translations.name.toLowerCase()
                      : option.name.toLowerCase()}
                  </StyledHeading>
                  {option.name === 'Color' && (
                    <StyledButton
                      type="button"
                      onClick={() => navigate('/contact/')}
                    >
                      {locale === 'en'
                        ? `Contact us for custom
                        ${currency !== 'USD' ? ' colours' : ' colors'}`
                        : translateString('product.contactColours', locale)}
                    </StyledButton>
                  )}
                  {option.name === 'Paper Type' && (
                    <>
                      <StyledButton
                        type="button"
                        onClick={togglePaperTypesInfoOpen}
                      >
                        {translateString('product.paperTypeQuestion', locale)}
                      </StyledButton>
                      <PaperTypesInfo
                        paperTypes={paperTypes}
                        isPaperTypesInfoOpen={isPaperTypesInfoOpen}
                        togglePaperTypesInfoOpen={togglePaperTypesInfoOpen}
                      />
                    </>
                  )}
                </StyledHeadingButton>
                {option.name === 'Paper Type' ? (
                  option.values
                    .filter(value => {
                      if (
                        (value === 'Self Adhesive' ||
                          value === 'Auto-adhésif') &&
                        isPaperSamples
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((value, id) => {
                      return (
                        <PaperTypeOption
                          key={id}
                          name={option.name}
                          value={value}
                          variants={variants}
                          translation={option?.translations?.values[id]}
                          getVariant={getVariant}
                          selectedVariant={selectedVariant}
                          handleChange={handleChange}
                          paperTypes={paperTypes}
                          isMural={isMural}
                          isPaperSamples={isPaperSamples}
                          unit={unit}
                          locale={locale}
                          i={i}
                        />
                      );
                    })
                ) : (
                  <StyledSelectWrap
                    variantColour={
                      option.name === 'Color' &&
                      getColourHex(selectedVariant.metafields)
                    }
                  >
                    <StyledSelect
                      onChange={e =>
                        handleChange(option.name, e.currentTarget.value, i)
                      }
                    >
                      {option.values.map((value, id) => (
                        <option
                          key={id}
                          value={value}
                          selected={selectedVariant.selectedOptions.find(
                            selectedOption => selectedOption.value === value
                          )}
                        >
                          {option.translations
                            ? option.translations.values[id]
                            : value}
                        </option>
                      ))}
                    </StyledSelect>
                  </StyledSelectWrap>
                )}
              </StyledOption>
            )
        )}
    </StyledProductOptions>
  );
};

export default ProductOptions;
