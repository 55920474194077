import React, { useContext, useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Sentry from '@sentry/browser';
import { Zoom, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
  standardTransition,
  breakpointSizes,
} from '../styles';
import { Container, Svg } from './ui';
import { ProductContext } from './Product';
import infoIcon from '../images/info-icon.inline.svg';
import Wizart from './Wizart';

const StyledProductGallery = styled.section`
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  ${minBreakpointQuery.smedium`
    padding-left: 0;
    padding-right: 0;
  `}
`;

const StyledMainImagesWrapper = styled.div`
  position: relative;
  background-color: #f1f1f1;

  ${maxBreakpointQuery.smedium`
    margin-right: -15px;
    margin-left: -15px;
  `}

  ${minBreakpointQuery.mlarge`
    background-color: ${standardColours.lightestGrey};
  `}
`;

const StyledOverlay = styled.div`
  background-color: ${standardColours.transparentBlack(0.3)};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndexLayers.first};
`;

const StyledMainImages = styled(Swiper)`
  overflow: visible;
  cursor: zoom-in;

  .slick-arrow {
    position: absolute;
    cursor: pointer;
    top: 50%;
    padding: 0;
    height: 30px;
    width: 30px;
    text-indent: -9999em;
    background-color: ${brandColours.senary};
    border: 1px solid ${standardColours.grey};
    z-index: ${zIndexLayers.first};
    transform: translateY(-50%);
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.small`
      height: 42px;
      width: 42px;
    `}

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      height: 6px;
      width: 6px;
      border: 2px solid ${brandColours.primary};
      transform: translateY(-50%) rotate(45deg);
      transition: ${standardTransition('border-color')};

      ${minBreakpointQuery.small`
        height: 8px;
        width: 8px;
      `}

      ${minBreakpointQuery.large`
        height: 10px;
        width: 10px;
        border-width: 3px;
      `}

      ${minBreakpointQuery.xxlarge`
        height: 12px;
        width: 12px;
      `}
    }

    &:hover {
      background-color: ${standardColours.lighterGrey};
    }

    &.slick-prev {
      left: 0px;

      ${minBreakpointQuery.mlarge`
        left: 3%;
      `}

      &:after {
        left: calc(50% - 2px);
        border-top: none;
        border-right: none;

        ${minBreakpointQuery.large`
          left: calc(50% - 3px);
        `}
      }
    }

    &.slick-next {
      right: 0px;

      ${minBreakpointQuery.mlarge`
        right: 3%;
      `}

      &:after {
        right: calc(50% - 2px);
        border-bottom: none;
        border-left: none;

        ${minBreakpointQuery.large`
          right: calc(50% - 3px);
        `}
      }
    }
  }

  .slick-track {
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    width: auto;
    overflow: hidden;

    &-active {
      ${StyledOverlay} {
        display: none;
      }
    }

    &-zoomed {
      /* width: 100%; */
    }
  }
`;

// moved up for click
const StyledMainImage = styled(GatsbyImage)`
  /* aspect-ratio: 6/5; */
`;

const StyledWizart = styled(Wizart)`
  ${maxBreakpointQuery.mlarge`
    margin-top: 20px;
  `}
`;

const StyledToolTipVisualiser = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    align-items: end;
    column-gap: 16px;
    max-width: 50%;
  `}
`;

const StyledToolTip = styled.div`
  display: none;

  ${minBreakpointQuery.mlarge`
    display: inline-flex;
    align-items: center;
    margin-top: 24px;
    ${fontSize(12)}
    color: ${standardColours.darkerGrey};
    border: 1px solid ${standardColours.darkerGrey};
    padding: 8px 16px;
  `}
`;

const StyledIcon = styled(Svg)`
  margin-right: 5px;
  fill: ${standardColours.darkerGrey};
  width: 16px;
  height: 16px;
`;

const StyledToolTipText = styled.p``;

const StyledThumbnailImagesWrapper = styled.div`
  max-width: 50%;
  position: relative;
`;

const StyledThumbnailImages = styled(Swiper)`
  display: none;

  ${minBreakpointQuery.mlarge`
    display: block;
    position: initial;
    margin-top: 16px;
    margin-left: 0;
    margin-right: 0;

    ${({ isThumbnailNavActive }) => {
      if (isThumbnailNavActive) {
        return css`
          margin-left: 40px;
          margin-right: 40px;
        `;
      }
    }}

    .swiper-slide {
      width: auto;
    }

    .swiper-button-next {
      position: absolute;
      cursor: pointer;
      top: calc(50% + 22px);
      padding: 0;
      height: 30px;
      width: 30px;
      background-color: ${brandColours.senary};
      border: 1px solid ${standardColours.grey};
      z-index: ${zIndexLayers.first};
      transform: translateY(-50%);
      transition: ${standardTransition('background-color')};

      ${({ isThumbnailNavActive }) => {
        if (isThumbnailNavActive) {
          return css`
            right: 0;
          `;
        }
      }}

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        display: block;
        height: 6px;
        width: 6px;
        border: 2px solid ${brandColours.primary};
        transform: translateY(-50%) rotate(45deg);
        transition: ${standardTransition('border-color')};
        border-bottom: none;
        border-left: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      cursor: pointer;
      top: calc(50% + 22px);
      padding: 0;
      height: 30px;
      width: 30px;
      background-color: ${brandColours.senary};
      border: 1px solid ${standardColours.grey};
      z-index: ${zIndexLayers.first};
      transform: translateY(-50%);
      transition: ${standardTransition('background-color')};

      ${({ isThumbnailNavActive }) => {
        if (isThumbnailNavActive) {
          return css`
            left: 0;
          `;
        }
      }}

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        display: block;
        height: 6px;
        width: 6px;
        border: 2px solid ${brandColours.primary};
        transform: translateY(-50%) rotate(45deg);
        transition: ${standardTransition('border-color')};
        border-top: none;
        border-right: none;
      }
    }
  `}
`;

const StyledThumbnailImageWrapper = styled.div`
  cursor: pointer;
  opacity: 0.3;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        opacity: 1;
      `;
    }
  }}
`;

const StyledThumbnailImage = styled(GatsbyImage)`
  aspect-ratio: 6/5;
`;

const ProductGallery = ({
  title,
  extraImages,
  variants,
  sampleVariants,
  getVariant,
  zoomTooltipMessaging,
  locale,
  ...props
}) => {
  const {
    selectedVariant,
    setSelectedVariant,
    selectedSampleVariant,
    setSelectedSampleVariant,
  } = useContext(ProductContext);

  const mainSlider = useRef();

  const variantImages = variants.filter(({ selectedOptions }) =>
    selectedOptions.find(
      ({ name, value }) => name === 'Paper Type' && value === 'Premium'
    )
  );

  let extraFirstVariantImages = [];

  extraImages &&
    extraImages.mainImages.map((image, i) => {
      // Check any images that are for other variants

      // Check for any capitalised custom data
      if (
        image.customData &&
        image.customData.Variant &&
        !image.customData.variant
      ) {
        image.customData.variant = image.customData.Variant;
      }

      if (image.customData && image.customData.variant) {
        // Get index of the variant
        const index = variantImages.findIndex(({ title }) =>
          title.toLowerCase().includes(image.customData.variant.toLowerCase())
        );
        if (index > 0) {
          return variantImages.splice(index + 1, 0, {
            ...variantImages[index],
            mainImage: image,
            thumbnailImage: extraImages.thumbnailImages[i],
          });
        } else {
          return;
        }
      } else {
        return extraFirstVariantImages.push({
          ...variantImages[0],
          mainImage: image,
          thumbnailImage: extraImages.thumbnailImages[i],
        });
      }
    });

  const images = [
    variantImages[0],
    ...extraFirstVariantImages,
    ...variantImages.slice(1),
  ];

  const duplicateImages = [...images, ...images];

  const colourIndex = selectedVariant.selectedOptions.findIndex(
    ({ name }) => name === 'Color'
  );

  const [isThumbnailNavActive, setThumbnailNavActive] = useState(false);
  const [isWizartAvailable, setWizartAvailable] = useState(false);

  const checkWizart = sku => {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Authorization', process.env.GATSBY_WIZART_TOKEN);

      const requestOptions = {
        method: 'GET',
        headers: headers,
      };

      try {
        fetch(
          `https://pim-client.wizart.ai/api/articles/available-vendor-codes?vendor_code=${sku}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result =>
            resolve(
              result &&
                result.data &&
                result.data.vendor_codes[sku] &&
                setWizartAvailable(result.data.vendor_codes[sku])
            )
          )
          .catch(error => reject(error));
      } catch (e) {
        Sentry.captureException(`Wizart error: ${e}`);
      }
    });
  };

  useEffect(() => {
    mainSlider.current.swiper.slideTo(
      images.findIndex(
        image =>
          JSON.stringify(image.selectedOptions[colourIndex].value) ===
          JSON.stringify(selectedVariant.selectedOptions[colourIndex].value)
      ) + images.length
    );
    mainSlider.current.swiper.update();

    checkWizart(selectedVariant.sku);
  }, [colourIndex, selectedVariant]);

  const changeSelectedVariant = (colour, i) => {
    const variant = getVariant(variants, {
      ...selectedVariant.selectedOptions,
      [colourIndex]: {
        name: 'Color',
        value: colour,
      },
    });
    setSelectedVariant(variant);

    const sampleVariant = getVariant(sampleVariants, {
      ...selectedSampleVariant.selectedOptions,
      [colourIndex]: {
        name: 'Color',
        value: colour,
      },
    });
    setSelectedSampleVariant(sampleVariant);

    mainSlider.current.swiper.slideTo(i + images.length);
    mainSlider.current.swiper.update();
  };

  const openVisualizer = () => {
    const entryPoint = new window.WEntryPoint({
      token: process.env.GATSBY_WIZART_TOKEN,
      vendorCode: selectedVariant.sku,
      element: document.getElementsByTagName('body')[0],
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'wizart-opened' });

    entryPoint.open();
  };

  return (
    <StyledProductGallery {...props}>
      <Helmet>
        {isWizartAvailable && (
          <script
            defer
            type="application/javascript"
            src="https://d35so7k19vd0fx.cloudfront.net/production/integration/entry-point.min.js"
          ></script>
        )}
      </Helmet>
      {duplicateImages && duplicateImages.length > 0 && (
        <>
          <StyledContainer>
            <StyledMainImagesWrapper>
              <StyledMainImages
                ref={mainSlider}
                modules={[Zoom, Navigation]}
                zoom={true}
                loop={true}
                navigation={{
                  nextEl: '.slick-next',
                  prevEl: '.slick-prev',
                }}
                breakpoints={{
                  [breakpointSizes.mlarge]: {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    loop: true,
                    centeredSlides: true,
                  },
                }}
                onSlideChangeTransitionEnd={swiper => {
                  if (swiper.activeIndex === 2) {
                    // move the last child to the start
                    const sliderWrapper =
                      document.getElementsByClassName('swiper-wrapper')[0];
                    const lastSlide = sliderWrapper.lastChild;
                    const firstSlide = sliderWrapper.firstChild;
                    sliderWrapper.insertBefore(lastSlide, firstSlide);
                    swiper.slideTo(3, 0, false);
                    swiper.update();
                  }
                }}
              >
                {duplicateImages.map(
                  ({ mainImage: { gatsbyImageData, alt } }, i) => (
                    <SwiperSlide
                      key={`main-image-${i}`}
                      id={`product-gallery-image-${i + 1}`}
                    >
                      <StyledOverlay className="swiper-slide-overlay" />
                      <div className="swiper-zoom-container">
                        <StyledMainImage
                          image={gatsbyImageData}
                          alt={alt || `${title} Image ${i + 1}`}
                          objectFit="contain"
                          className="swiper-zoom-target"
                        />
                      </div>
                    </SwiperSlide>
                  )
                )}
                <div
                  className="slick-arrow slick-prev"
                  onClick={() => mainSlider.current.swiper.slidePrev()}
                />
                <div className="slick-arrow slick-next" />
              </StyledMainImages>
            </StyledMainImagesWrapper>
          </StyledContainer>
          <Container narrow={true}>
            <StyledToolTipVisualiser>
              <StyledToolTip>
                <StyledIcon image={infoIcon} />
                <StyledToolTipText>{zoomTooltipMessaging}</StyledToolTipText>
              </StyledToolTip>
              {isWizartAvailable && (
                <StyledWizart locale={locale} openVisualizer={openVisualizer} />
              )}
            </StyledToolTipVisualiser>
            {images.length > 1 && (
              <StyledThumbnailImagesWrapper>
                <StyledThumbnailImages
                  modules={[Navigation]}
                  navigation={true}
                  slidesPerView="auto"
                  spaceBetween={12}
                  onResize={swiper => {
                    // See if navigation is active and set state for styling changes
                    setThumbnailNavActive(swiper.allowSlidePrev);
                  }}
                  isThumbnailNavActive={isThumbnailNavActive}
                >
                  {images.map((variant, i) => {
                    const {
                      thumbnailImage: { gatsbyImageData, alt },
                      selectedOptions,
                    } = variant;

                    const colour = selectedOptions.find(
                      ({ name }) => name === 'Color'
                    ).value;

                    return (
                      <SwiperSlide key={`thumbnail-image-${i}`}>
                        <StyledThumbnailImageWrapper
                          onClick={() => changeSelectedVariant(colour, i)}
                          isSelected={selectedVariant.selectedOptions.find(
                            ({ value }) => value === colour
                          )}
                        >
                          <StyledThumbnailImage
                            image={gatsbyImageData}
                            alt={alt || `${title} Image ${i + 1}`}
                          />
                        </StyledThumbnailImageWrapper>
                      </SwiperSlide>
                    );
                  })}
                </StyledThumbnailImages>
              </StyledThumbnailImagesWrapper>
            )}
          </Container>
        </>
      )}
    </StyledProductGallery>
  );
};

export default ProductGallery;

export const ProductGalleryImageFragments = graphql`
  fragment ShopifyProductGalleryMainImageFragment on ShopifyImage {
    gatsbyImageData(height: 545)
    alt: altText
    src
  }

  fragment ShopifyProductGalleryThumbnailImageFragment on ShopifyImage {
    gatsbyImageData(width: 65)
    alt: altText
  }

  fragment DatoCmsProductGalleryMainImageFragment on DatoCmsFileField {
    gatsbyImageData(height: 545)
    alt
    url
    customData
  }

  fragment DatoCmsProductGalleryThumbnailImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 65)
    alt
    customData
  }
`;
