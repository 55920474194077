import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { brandColours, fontSize, fontWeights } from '../styles';
import { Button, HtmlContent } from './ui';
import { getSingleLocaleFields } from '../utils';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';

const StyledCustomisationInfo = styled(PopOutSidebar)`
  right: 0;
`;

const StyledText = styled(HtmlContent)`
  p {
    ${fontSize(14)};
  }
`;

const StyledItems = styled.ul`
  margin-top: 32px;
  display: grid;
  row-gap: 40px;
`;

const StyledItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledIcon = styled.img`
  width: 35px;
`;

const StyledHeading = styled.h3`
  ${fontSize(18)};
  font-weight: ${fontWeights.semibold};
  margin-left: 16px;
`;

const StyledItemContent = styled.p`
  width: 100%;
  margin-top: 10px;
  ${fontSize(14)}
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  border-radius: 0;
  background-color: ${brandColours.primary};
`;

const CustomisationInfo = ({
  locale,
  isCustomisationInfoOpen,
  toggleCustomisationInfoOpen,
}) => {
  const { allDatoCmsCustomisationInfo } = useStaticQuery(graphql`
    query CustomisationInfoQuery {
      allDatoCmsCustomisationInfo {
        nodes {
          locale
          heading
          text
          items {
            heading
            text
            icon {
              url
              alt
            }
          }
          link {
            text
            page {
              ...LinkFragment
            }
          }
        }
      }
    }
  `);

  const { heading, text, items, link } = getSingleLocaleFields(
    allDatoCmsCustomisationInfo,
    locale
  );

  return (
    <StyledCustomisationInfo
      heading={heading}
      isOpen={isCustomisationInfoOpen}
      toggleOpen={toggleCustomisationInfoOpen}
    >
      <StyledContent>
        <StyledText html={text} />
        <StyledItems>
          {items.map(({ heading, text, icon: { url, alt } }, id) => (
            <StyledItem key={id}>
              <StyledIcon src={url} alt={alt} />
              <StyledHeading>{heading}</StyledHeading>
              <StyledItemContent>{text}</StyledItemContent>
            </StyledItem>
          ))}
        </StyledItems>
        <StyledButton to={link.page}>{link.text}</StyledButton>
      </StyledContent>
    </StyledCustomisationInfo>
  );
};

export default CustomisationInfo;
