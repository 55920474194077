import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  fontWeights,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  max-width: 670px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;
`;

const StyledItem = styled.article`
  margin: 16px 0;
  padding: 22px 16px;
  background-color: ${standardColours.lighterGrey};
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 6px;
    height: 12px;
    width: 12px;
    border: 3px solid ${standardColours.black};
    border-top: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(-45deg);
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Accordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordion>
      <StyledContainer>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem key={`accordion-item-${i}`}>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledContent html={content} display={display} />
            </StyledItem>
          );
        })}
      </StyledContainer>
    </StyledAccordion>
  );
};

export default Accordion;
