import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
  maxBreakpointQuery,
} from '../styles';

const StyledIconContentBlocks = styled.section`
  ${minBreakpointQuery.medium`
    border-top: 1px solid ${standardColours.darkGrey};
  `}
`;

const StyledContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1800px;
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: grid;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledItem = styled.article`
  margin: 0 auto;
  max-width: 600px;
  color: ${standardColours.white};
  text-align: center;
  padding: 35px 0;

  ${maxBreakpointQuery.medium`
    border-bottom: 1px solid ${standardColours.darkGrey};

    &:last-child {
      border-bottom: none;
    }
  `}

  ${minBreakpointQuery.medium`
    padding: 35px 20px;
    border-right: 1px solid ${standardColours.darkGrey};

    &:last-child {
      border-right: none;
    }
  `}

  ${minBreakpointQuery.large`
    padding: 60px 30px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 70px 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 70px 70px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 75px 70px;
  `}

  ${minBreakpointQuery.xxxlarge`
    padding: 80px 110px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding: 80px 130px;
  `}
`;

const StyledIcon = styled.img`
  margin-bottom: 10px;
  width: 60px;
  height: 60px;

  ${minBreakpointQuery.medium`
    margin-bottom: 15px;
  `}
`;

const StyledHeading = styled.h3`
  color: ${standardColours.black};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  ${fontSize(22)};

  ${minBreakpointQuery.small`
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  color: ${standardColours.black};
  margin-top: 10px;
  line-height: 1.5;

  ${minBreakpointQuery.medium`
    margin-top: 15px;
  `}
`;

const IconContentBlocks = ({ heading, items }) => (
  <StyledIconContentBlocks>
    <StyledContainer>
      <StyledHiddenHeading>{heading}</StyledHiddenHeading>
      <StyledItems>
        {items.map(({ id, icon, heading, text }) => (
          <StyledItem key={id}>
            <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
            <StyledHeading>{heading}</StyledHeading>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </StyledContainer>
  </StyledIconContentBlocks>
);

export default IconContentBlocks;
