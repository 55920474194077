import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, fontWeights, fontSize } from '../styles';
import { Link } from './ui';

const StyledProjectCard = styled.article``;

const StyledHeading = styled.h3`
  margin-top: 8px;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-top: 10px;
    ${fontSize(15)};
  `}
`;

const ProjectCard = ({ heading, link, image }) => (
  <StyledProjectCard>
    <Link to={link}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
    </Link>
    <StyledHeading>
      <Link to={link}>{heading}</Link>
    </StyledHeading>
  </StyledProjectCard>
);

export default ProjectCard;
